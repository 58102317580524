<div class="container">
  <div class="section-name">{{ 'profile.headers.authorization' | translate }}</div>
  <div class="content mat-elevation-z5">
    <label> {{ 'profile.authorization.label' | translate }}</label>
    <div class="authorization">
      <span
        class="disabled-toogle-label"
        [ngClass]="{
          disabled: isMfaEnabled$ | async,
          enabled: !(isMfaEnabled$ | async)
        }"
      >
        {{ 'profile.authorization.disabled' | translate }}
      </span>
      <mat-slide-toggle
        class="authorization__toggle"
        [checked]="isMfaEnabled$ | async"
        [companyId]="companyId$ | async"
        appEnableAuthorizationToggleChange
        color="primary"
      >
        {{ 'profile.authorization.enabled' | translate }}
      </mat-slide-toggle>
    </div>
  </div>
</div>
<!-- <app-data-privacy *ngIf="!!user; else showSpinnerTemplate"></app-data-privacy>  -->