import {HttpClient} from '@angular/common/http';
import {Injectable, SecurityContext} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {FlightFrame} from '@app/flights/components/flight-log/flight-log-parser';
import {GeoJSON} from 'geojson';
import {GridLayer, icon, LatLng, latLng, Layer, Map, ZoomPanOptions} from 'leaflet';
import {BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject, zip} from 'rxjs';
import {filter, map, shareReplay, take, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {
  ASSET_GEOMETRY_TYPE,
  AssetType,
  AtlasAssetModel,
  AtlasGeojsonAssetModel
} from '../../core/models/api/atlas.model';
import {AtlasLocalStorageService} from './atlas-local-storage.service';
import {LibraryItem} from '@app/library/models/folder-file.model';
import {createMapIcon} from '../marker-icons/custom-map-pointer';
import {AtlasApiService} from '@app/atlas/services/atlas-api.service';
import {
  atlasConfig,
  defaultCircleIconProps,
  defaultHouseMarkerColor,
  defaultMarkerSize,
  StatusMarkerColors,
  taskLayers
} from '@app/atlas/atlas.config';
import {MAP_VIEW} from '../model/map-view.mode';
import {DomSanitizer} from '@angular/platform-browser';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';
import {GeoJSONFeature} from '../model/mission-geojson.model';
import {CompanyModel, MarkersSettings} from '@app/core/models/api/company-model';
import {GeojsonFeatureState} from '../model/marker.model';
import {UserService} from '@app/core/services/api/user.service';
import {UserModel} from '@app/core/models/api/user-model';
declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class AtlasService {
  public totalAssetsCount: number = 0;
  public failedAssetsCount: number = 0;
  public loadedAssetsCount: number = 0;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public currentViewPosition: string;
  public currentZoom: number;
  public isloadingInitialPosition$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isWeatherMapEnabledForUser: boolean = false;
  public isStoredDefaultHardCodedPosition: boolean = false;
  public isReadytoDetectAtlasChanges$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public hasLayerGroupChanged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public hasAllLayersLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeStreamingDevice$: ReplaySubject<UserDeviceJoined> = new ReplaySubject<UserDeviceJoined>(1);
  public deviceFlightFrame$: ReplaySubject<FlightFrame> = new ReplaySubject<FlightFrame>(1);
  public deviceFlightLayers$: ReplaySubject<Layer[]> = new ReplaySubject<Layer[]>(1);
  public totalAssets: number = 0;
  public totalAtlasItems: number = 0;
  public totalAssetsLoaded: number = 0;
  public isCompareLayersOpen: boolean = false;
  public avoidLoadLocalStorageAssets: boolean = false;
  public hasToRemoveAssetsHiglight: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly selectedLayers$: Observable<AtlasAssetModel[]>;
  public readonly assets$: Observable<AtlasAssetModel[]>;
  public readonly hasDashboardLayer$: Observable<boolean>;
  public hasToSkipLoadAssets: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public availableColors = ['#11ACA4', '#383DC3', '#F57A12', '#D93577', '#7379F9', '#67DC5F'];
  public isMarkerMenuOpened: boolean = false;

  private hasToDetectChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public hasToDetectChanges$: Observable<boolean> = this.hasToDetectChanges.asObservable();
  public goToAtlasItemIndex$: Subject<number> = new Subject<number>();

  private isLayersControlSideBarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLayersControlSideBarOpen$: Observable<boolean> = this.isLayersControlSideBarOpen.asObservable();
  public hasToBlockEvents = false;
  public hasToShowInfoPopup = true;
  public hasToSkipFitBoundsOnLoadLayers = false;
  public hasToMoveToUploadedLayer = false;
  public hasToHideCursor = false;
  public totalGeojsonFeatures: number = 0;
  public hasInitialCenterSettings: boolean = false;
  public atlasFinishDestroy: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public myCompany$: Observable<CompanyModel> = this.userService.myCompany$;
  public user$: Observable<UserModel> = this.userService.user$;
  public userDevices$: Observable<UserDeviceJoined[]> = this.userService.userDevices$;

  private _assets = new BehaviorSubject<AtlasAssetModel[]>([]);
  private _selectedLayers = new BehaviorSubject<AtlasAssetModel[]>([]);
  public currentPopupIndex: number = -1;
  public isPopupOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasAllGeojsonAssetsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public totalGeojsonAssetsLoaded = 0;
  public totalGeojsonAssets = 0;
  public geojsonMarkerIconSettings: BehaviorSubject<MarkersSettings> = new BehaviorSubject(null);
  public defaultAssetsView = {};

  get selectedLayers(): AtlasAssetModel[] {
    return this._selectedLayers.getValue();
  }
  get assets(): AtlasAssetModel[] {
    return this._assets.getValue();
  }

  fetchAssetById(id: string): Observable<AtlasAssetModel> {
    return this.atlasApiService.getById(id);
  }

  getAssetById(id: string): AtlasAssetModel {
    // todo PERF: switch from list to dict
    return this.dataStore.assets.find(a => a.id === id);
  }

  private dataStore = {
    assets: [],
    selectedLayers: []
  };

  constructor(
    private atlasLocalStorageService: AtlasLocalStorageService,
    private atlasApiService: AtlasApiService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private routerFacadeStoreService: RouterFacadeStoreService,
    private userService: UserService
  ) {
    this.assets$ = this._assets.asObservable().pipe(shareReplay(1));
    this.selectedLayers$ = this._selectedLayers.asObservable().pipe(shareReplay(1));
    this.hasDashboardLayer$ = this.assets$.pipe(map(assets => assets.some(asset => !!asset.dashboard)));
    this.updateDefaultAssetsView();
    this.setGeojsonMarkerIconSettings();
  }

  public setGeojsonMarkerIconSettings() {
    this.myCompany$
      .pipe(
        filter(company => !!company),
        take(1),
        map(company => company.atlas?.settings?.markers)
      )
      .subscribe(settings => {
        this.geojsonMarkerIconSettings.next(settings);
      });
  }

  public updateDefaultAssetsView(): void {
    this.defaultAssetsView = this.atlasLocalStorageService.transformLocalStorageToJSON('savedLayer');
  }

  public hasSavedLayers(): boolean {
    return !!this.atlasLocalStorageService.getItem('savedLayer');
  }

  public setHasInitialCenterSettings(hasInitialCenterSettings: boolean): void {
    this.hasInitialCenterSettings = hasInitialCenterSettings;
  }

  public async loadLayerById(layerId: string): Promise<AtlasAssetModel> {
    let layer = this.getAssetById(layerId);
    if (layer) {
      return layer;
    }

    try {
      layer = await firstValueFrom(this.fetchAssetById(layerId));
    } catch (error) {
      return null;
    }

    this.addAssets([layer]);
    return this.getAssetById(layer.id);
  }

  public setTotalGeojsonFeatures(totalFeatures: number) {
    this.totalGeojsonFeatures = totalFeatures;
  }

  public setCurrentPopupIndex(currentPopupIndex: number): void {
    this.currentPopupIndex = currentPopupIndex;
  }

  public setIsPopupOpened(isPopupOpened: boolean) {
    this.isPopupOpened.next(isPopupOpened);
  }

  public setIsMarkerMenuOpened(isMarkerMenuOpened: boolean): void {
    this.isMarkerMenuOpened = isMarkerMenuOpened;
  }

  public setHasToHideCursor(hasToHideCursor: boolean): void {
    this.hasToHideCursor = hasToHideCursor;
  }

  public setHasToBlockEvents(hasToBlockEvents: boolean): void {
    this.hasToBlockEvents = hasToBlockEvents;
  }

  public setHasToShowInfoPopup(hasToShowInfoPopup: boolean): void {
    this.hasToShowInfoPopup = hasToShowInfoPopup;
  }

  public setHasToSkipFitBoundsOnLoadLayers(hasToSkipFitBoundsOnLoadLayers: boolean) {
    this.hasToSkipFitBoundsOnLoadLayers = hasToSkipFitBoundsOnLoadLayers;
  }

  public setHasToMoveToUploadedLayer(hasToMoveToUploadedLayer: boolean) {
    this.hasToMoveToUploadedLayer = hasToMoveToUploadedLayer;
  }

  public sethasToSkipLoadAssets(hasToSkipLoadAssets: boolean): void {
    this.hasToSkipLoadAssets.next(hasToSkipLoadAssets);
  }

  public setHasToRemoveAssetsHiglight(hasToRemoveAssetsHiglight: boolean) {
    this.hasToRemoveAssetsHiglight.next(hasToRemoveAssetsHiglight);
  }

  public setAvoidLoadLocalStorageAssets(avoidLoadLocalStorageAssets: boolean) {
    this.avoidLoadLocalStorageAssets = avoidLoadLocalStorageAssets;
  }

  public setIsCompareLayersOpen(value: boolean): void {
    this.isCompareLayersOpen = value;
  }

  public changeLayerColor(asset: AtlasAssetModel, colorIndex: number) {
    const params = {
      color: this.availableColors[colorIndex]
    };
    this.updateAsset(asset, params, true).pipe(take(1)).subscribe();
  }

  public updateLayerColor(leafletLayer: any, color: string) {
    leafletLayer?.setStyle({color});
  }

  public bringToFrontPolygon(asset: AtlasAssetModel) {
    if (asset.type === AssetType.ORTHOPHOTOMAP) {
      // eslint-disable-next-line no-magic-numbers
      asset.leafletLayer.setZIndex(100);
      return;
    }
    asset.leafletLayer?.bringToFront();
  }

  public bringToBackPolygon(asset: AtlasAssetModel) {
    if (asset.type === AssetType.ORTHOPHOTOMAP) {
      // eslint-disable-next-line no-magic-numbers
      asset.leafletLayer.setZIndex(0);
      return;
    }
    asset.leafletLayer?.bringToBack();
  }

  public createCircleIcon({
    color = defaultCircleIconProps.color,
    borderColor = defaultCircleIconProps.borderColor,
    borderSize = defaultCircleIconProps.borderSize,
    radius = defaultCircleIconProps.radius
  }: {
    color: string;
    borderColor: string;
    borderSize: number;
    radius: number;
  }) {
    return L.divIcon({
      className: 'circle-icon',
      html: `<div style="
      background-color: ${color};
      border-style:solid; 
      border-radius: 50px; 
      border-color:${borderColor}; 
      border-width:${borderSize}px; 
      width:${radius * 2}px; height:${radius * 2}px"></div>`,
      iconAnchor: [radius, radius],
      attribution: color
    });
  }

  public sanitizeMarkerSettings({color, borderColor, borderSize, radius}) {
    const sanitizeString = (value: string): string => {
      return this.sanitizer.sanitize(SecurityContext.HTML, value) || '';
    };
    const sanitizeNumber = (value: number): number => {
      const parsedValue = Number(value);
      return isNaN(parsedValue) && typeof parsedValue !== 'number' ? 0 : parsedValue;
    };
    return {
      color: sanitizeString(color),
      borderColor: sanitizeString(borderColor),
      borderSize: sanitizeNumber(borderSize),
      radius: sanitizeNumber(radius)
    };
  }

  public updateMarkerColor(asset, layer) {
    const markerSettings = this.geojsonMarkerIconSettings.value;
    if (markerSettings && markerSettings.style === 'circle') {
      const {borderColor, borderSize, radius} = markerSettings;
      const iconProperties = this.sanitizeMarkerSettings({color: asset.color, borderColor, borderSize, radius});
      const newIcon = this.createCircleIcon(iconProperties);
      layer.setIcon(newIcon);
      layer.update();
      return;
    }
    const updatedIcon = createMapIcon({
      size: defaultMarkerSize,
      color: asset.color || atlasConfig.defaultMarkerColor,
      className: layer.options.icon.options.className
    });
    layer.setIcon(updatedIcon);
    layer.update();
  }

  public createLocationIcon(layer) {
    const updatedIcon = icon({
      iconUrl: 'assets/icons/atlas/location.svg',
      iconSize: [26, 35],
      iconAnchor: [13, 35]
    });
    layer.setIcon(updatedIcon);
    layer.update();
  }

  public hasAllMarkersStatusAssigned(asset: AtlasAssetModel) {
    if (asset.type !== AssetType.GEOJSON) {
      return null;
    }
    const filteredFeatures = ((asset as AtlasGeojsonAssetModel).geojson as any).features.filter(
      feature => feature.geometry?.type === ASSET_GEOMETRY_TYPE.POINT
    );
    return (
      filteredFeatures.length > 0 &&
      filteredFeatures.every(feature => {
        return !!feature.properties.state || feature.state;
      })
    );
  }

  addSelectedLayer(asset: AtlasAssetModel): void {
    this.dataStore.selectedLayers.push(asset);
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  removeSelectedLayer(assetId: string) {
    if (this.dataStore.selectedLayers.length === 0) {
      return;
    }
    this.dataStore.selectedLayers = this.dataStore.selectedLayers.filter(sl => sl.id !== assetId);
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  clearSelectedLayers() {
    this.dataStore.assets.forEach(a => (a.isSelected = false));
    this.dataStore.selectedLayers = [];
    this._selectedLayers.next([]);
  }

  selectAllLayers() {
    this.dataStore.assets.forEach(a => (a.isSelected = true));
    this.dataStore.selectedLayers = this.dataStore.assets;
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  convertToShp(geojson: GeoJSON, name: string) {
    return this.atlasApiService.convertToShapeFile(geojson, name);
  }

  fetchAssets(): Observable<AtlasAssetModel[]> {
    return this.atlasApiService.listAtlasItems();
  }

  public getAssignAssetsPreview(libraryItemId: LibraryItem['id']): Observable<any> {
    return this.atlasApiService.getAssignAssetsPreview(libraryItemId);
  }

  public assignAssets({
    folderId,
    atlasAssetId,
    groupNameKey
  }: {
    folderId: string;
    atlasAssetId: string;
    groupNameKey: string;
  }): Observable<any> {
    return this.atlasApiService.assignAssets(folderId, atlasAssetId, groupNameKey);
  }

  public assignAssetsNoLayer({folderId, gimbalPitch}: {folderId: string; gimbalPitch: string}): Observable<any> {
    return this.atlasApiService.assignAssets(folderId, undefined, gimbalPitch);
  }

  public getAssets(getLatestDays?: number) {
    this.isLoading$.next(true);
    return this.atlasApiService.listAtlasItems().pipe(
      tap(async (assets: AtlasAssetModel[]) => {
        if (!!getLatestDays) {
          //temporary workaround for large orthophoto model datasets
          const onlyRecentAssets = assets.filter(asset => {
            if (asset.type !== AssetType.ORTHOPHOTOMAP) {
              // allow all non-orthophoto assets
              return true;
            }
            return asset.createdAt > Date.now() - getLatestDays * 24 * 60 * 60 * 1000;
          });
          assets = onlyRecentAssets;
        }
        this.totalAssetsCount = assets.length;
        this.totalGeojsonAssets = assets.filter(asset => asset.type === AssetType.GEOJSON).length;
        if (this.totalAssetsCount === 0) {
          this.setHasAllLayersLoaded(true);
        }
        console.info(`START LOADING ${this.totalAssetsCount} ASSETS`); //
        this.addAssets(assets);
        this.assetLoadEnd();
      })
    );
  }

  public getCreatedAssets() {
    return this.atlasApiService.listAtlasItems().pipe(
      map(assets => {
        const currentTimestamp = Date.now();
        // eslint-disable-next-line no-magic-numbers
        const fewSecondsAgo = currentTimestamp - 30 * 1000;
        return assets.filter(asset => asset.createdAt >= fewSecondsAgo);
      })
    );
  }

  public updateAsset(asset: AtlasAssetModel, payload: Partial<AtlasAssetModel>, avoidUpdateCache?: boolean) {
    return this.atlasApiService.update(asset.id, payload).pipe(
      tap(() => {
        if (avoidUpdateCache) {
          return;
        }
        this.updateCache([asset], payload);
      })
    );
  }

  public moveMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.atlasApiService.moveMarkers(assetIdFrom, assetIdTo, indexes);
  }

  public copyMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.atlasApiService.copyMarkers(assetIdFrom, assetIdTo, indexes);
  }

  public changeMarkerState(assetId: string, features: {featureId: string; state: string}[]) {
    return this.atlasApiService.changeMarkerState(assetId, features);
  }

  public createEmptyAsset(payload: {name: string; type: AssetType; key: string; groupName: string; jobId?: string}) {
    return this.atlasApiService.create(payload);
  }

  updateAssets(assets: AtlasAssetModel[], payload: Partial<AtlasAssetModel>) {
    const updateArr$ = assets.map(a => this.atlasApiService.update(a.id, payload));
    return zip(...updateArr$).pipe(
      tap(() => {
        this.updateCache(assets, payload);
        this.clearSelectedLayers();
      })
    );
  }

  public removeAsset({assetId, avoidUpdateCache}: {assetId: string; avoidUpdateCache: boolean}) {
    return this.atlasApiService.removeById(assetId).pipe(
      tap(() => {
        if (avoidUpdateCache) {
          return;
        }
        this.sethasToSkipLoadAssets(true);
        this.removeFromCache([assetId]);
        this.removeSelectedLayer(assetId);
        this.sethasToSkipLoadAssets(false);
      })
    );
  }

  public removeAssets(assets: AtlasAssetModel[]) {
    const delArr$ = assets.map(a => this.atlasApiService.removeById(a.id));
    return zip(...delArr$).pipe(
      tap(() => {
        this.sethasToSkipLoadAssets(true);
        this.removeFromCache(assets.map(asset => asset.id));
        this.clearSelectedLayers();
        this.sethasToSkipLoadAssets(false);
      })
    );
  }

  public toggleAssetLayer({isDisplaying, layer, map}: {isDisplaying: boolean; layer: any; map: Map}) {
    if (!layer) {
      return;
    }
    isDisplaying ? layer?.addTo(map) : layer?.removeFrom(map);
  }

  convertDMS(lat, lng) {
    function toDegreesMinutesAndSeconds(coordinate) {
      const absolute = Math.abs(coordinate);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return degrees + '°' + minutes + "'" + seconds + "''";
    }

    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = Math.sign(lng) >= 0 ? 'E' : 'W';

    return latitude + latitudeCardinal + ' ' + longitude + longitudeCardinal;
  }

  public addAssets(assets) {
    if (!this.dataStore.assets.length) {
      this.dataStore.assets = assets;
    } else {
      // deduplicate existing items
      assets.forEach(asset => {
        // add if doesnt exist yet
        if (!this.dataStore.assets.find(i => i.id === asset.id)) {
          this.dataStore.assets.push(asset);
        }
      });
    }
    this._assets.next([...this.dataStore.assets]);
    this.totalAssets = this.dataStore.assets.length;
  }

  public addNewAssets(newAsset) {
    const allAssets = this.assets;
    if (!allAssets.find(i => i.id === newAsset.id)) {
      allAssets.push(newAsset);
    }
    this._assets.next(allAssets);
    this.dataStore.assets = allAssets;
    this.totalAssets = this.dataStore.assets.length;
  }

  public removeOldAsset(oldAsset) {
    const allAssets = this.assets;
    const oldAssetIndex = allAssets.findIndex(i => i.id === oldAsset.id);
    if (oldAssetIndex >= 0) {
      allAssets.splice(oldAssetIndex, 1);
    }
    this._assets.next(allAssets);
    this.dataStore.assets = allAssets;
    this.totalAssets = this.dataStore.assets.length;
  }

  public displayUploadedAsset(map: Map, asset: AtlasAssetModel) {
    asset.isDisplaying = true;
    this.toggleAssetLayer({isDisplaying: true, layer: asset.leafletLayer, map});
    this.toggleHasToDetectChanges();
  }

  public displayAssets(map: Map) {
    this.dataStore.assets.forEach(asset => {
      asset.isDisplaying = true;
      this.toggleAssetLayer({isDisplaying: true, layer: asset.leafletLayer, map});
    });
    // Due perf issues we update the references instead of mapping the entire array
    // this._assets.next(this.dataStore.assets);
    this.toggleHasToDetectChanges();
  }

  public displayLayers(map: Map) {
    this.dataStore.assets.forEach(asset => {
      this.toggleAssetLayer({isDisplaying: asset.isDisplaying, layer: asset.leafletLayer, map});
    });
    this.toggleHasToDetectChanges();
  }

  assetLoadEnd() {
    this.isLoading$.next(false);
  }

  getAssetData<T>(key: string, responseType: 'json' | 'text' | 'arraybuffer'): Promise<T> {
    return this.http
      .get<T>(this.getCDNPrefixedUrl(key), {
        responseType: responseType as any
        // withCredentials: true
      })
      .pipe(
        tap(() => {
          ++this.loadedAssetsCount;
        })
      )
      .toPromise()
      .catch(err => {
        return this.handleError(key, err);
      });
  }

  handleError(key, error) {
    this.failedAssetsCount += 1;
    console.error('Failed to fetch asset: ' + key + ' error: ' + JSON.stringify(error));
    return Promise.reject(error);
  }

  loadInitialPositionFromLocalStorage(map: Map): void {
    const coordinates: {lat: number; lng: number} = JSON.parse(this.currentViewPosition);
    const mapCenter = latLng([coordinates.lat, coordinates.lng]);
    map.setView(mapCenter, this.currentZoom, {
      animate: false
    } as ZoomPanOptions);
    this.isloadingInitialPosition$.next(true);
  }

  loadPreviousPositionFromLocalStorage(map: Map): void {
    const mapPosition = this.atlasLocalStorageService.getItem('previousViewPosition');
    const mapZoom = +this.atlasLocalStorageService.getItem('previousZoom');
    const coordinates: {lat: number; lng: number} = JSON.parse(mapPosition);
    const mapCenter = latLng([coordinates.lat, coordinates.lng]);
    map.setView(mapCenter, mapZoom, {
      animate: false
    } as ZoomPanOptions);
  }

  public hasMapPropertiesStored(): boolean {
    this.currentViewPosition = this.atlasLocalStorageService.getItem('currentViewPosition');
    this.currentZoom = +this.atlasLocalStorageService.getItem('currentZoom');
    const mapView = this.atlasLocalStorageService.getItem('mapView');
    const isWeatherMapDisplayed = this.atlasLocalStorageService.getItem('isWeatherMapDisplayed');
    if (this.currentZoom && this.currentViewPosition && mapView && isWeatherMapDisplayed) {
      return true;
    }
    return false;
  }

  public getStoredMapview(): MAP_VIEW {
    return this.atlasLocalStorageService.getItem('mapView') as MAP_VIEW;
  }

  isWeatherMapDisplayed(): boolean {
    return this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') === 'true';
  }

  public setFavouritePropertiesInStorage(
    centerPosition: LatLng,
    zoomValue: number,
    mapView: MAP_VIEW,
    isWeatherMapDisplayed?: boolean
  ) {
    const currentViewPosition = JSON.stringify(centerPosition);
    this.atlasLocalStorageService.setItem('currentViewPosition', currentViewPosition);
    this.atlasLocalStorageService.setItem('currentZoom', zoomValue + '');
    this.atlasLocalStorageService.setItem('mapView', mapView + '');
    this.atlasLocalStorageService.setItem('isWeatherMapDisplayed', isWeatherMapDisplayed + '');
  }

  setPreviousAtlasView(centerPosition: LatLng, zoomValue: number) {
    const currentViewPosition = JSON.stringify(centerPosition);
    this.atlasLocalStorageService.setItem('previousViewPosition', currentViewPosition);
    this.atlasLocalStorageService.setItem('previousZoom', zoomValue + '');
  }

  public setHasAllLayersLoaded(hasAllLayersLoaded: boolean) {
    this.hasAllLayersLoaded$.next(hasAllLayersLoaded);
  }

  public handleDetectAssetChanges() {
    this.totalAssetsLoaded++;
    this.isReadytoDetectAtlasChanges$.next(true);
    if (this.totalAssetsLoaded === this.totalAssets) {
      this.setHasAllLayersLoaded(true);
    }
  }

  public handleGeojsonAssetsLoaded() {
    this.totalGeojsonAssetsLoaded++;
    if (this.totalGeojsonAssetsLoaded === this.totalGeojsonAssets) {
      this.hasAllGeojsonAssetsLoaded.next(true);
    }
  }

  clearAtlasLocalStorage() {
    this.atlasLocalStorageService.removeItem('currentViewPosition');
    this.atlasLocalStorageService.removeItem('currentZoom');
    this.atlasLocalStorageService.removeItem('mapView');
    this.atlasLocalStorageService.removeItem('isWeatherMapDisplayed');
  }

  public deviceUpdated(map: Map): void {
    map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        if ((layer.options as any)?.isDeviceMarker) {
          map.removeLayer(layer);
        }
      }
    });
  }

  public hideAllLayers(): void {
    this.assets.forEach(asset => {
      asset.isDisplaying = false;
    });
  }
  public showAllLayers(): void {
    this.assets.forEach(asset => {
      if (asset.groupName === taskLayers) {
        asset.isDisplaying = false;
      }
      asset.isDisplaying = true;
    });
  }

  // eslint-disable-next-line rxjs/finnish
  public previousUrl(): Observable<string> {
    return this.routerFacadeStoreService.selectPreviousUrl().pipe(take(1));
  }

  public toggleHasToDetectChanges(): void {
    this.hasToDetectChanges.next(!this.hasToDetectChanges.value);
  }

  public setAsOpenLayersControlSideBar(): void {
    this.isLayersControlSideBarOpen.next(true);
  }

  public setAsCloseLayersControlSideBar(): void {
    this.isLayersControlSideBarOpen.next(false);
  }

  private removeExistentLayer(map, layers): void {
    if (map.hasLayer(layers.satelliteView)) {
      map.removeLayer(layers.satelliteView);
      return;
    }
    if (map.hasLayer(layers.roadView)) {
      map.removeLayer(layers.roadView);
      return;
    }
    if (map.hasLayer(layers.hybridView)) {
      map.removeLayer(layers.hybridView);
      return;
    }
  }

  public changeMapView(
    map: Map,
    mapView: MAP_VIEW,
    satelliteView: GridLayer,
    roadView: GridLayer,
    hybridView: GridLayer
  ) {
    if (!map) {
      return;
    }
    const mapViewMapping = {
      [MAP_VIEW.SATELLITE]: satelliteView,
      [MAP_VIEW.HYBRID]: hybridView,
      [MAP_VIEW.ROADMAP]: roadView
    };

    this.removeExistentLayer(map, {satelliteView, roadView, hybridView});
    map.addLayer(mapViewMapping[mapView]);
  }

  public convertAssetToMission(assetId: string, si: string) {
    return this.atlasApiService.convertAssetToMission(assetId, si);
  }

  public displayAsset(assetId: string, isDisplaying: boolean, map: Map): void {
    const asset = this.getAssetById(assetId);
    asset.isDisplaying = isDisplaying;
    this.toggleAssetLayer({isDisplaying, layer: asset, map});
    this.toggleHasToDetectChanges();
  }

  private getCDNPrefixedUrl(key) {
    return `${environment.atlasCDNDomain}/${key}`;
  }

  private removeFromCache(assetsToRemove: string[]) {
    function excludeAssetsToRemove(a) {
      return !assetsToRemove.find(sl => sl === a.id);
    }
    this.dataStore.assets = this.dataStore.assets.filter(excludeAssetsToRemove);
    this._assets.next([...this.dataStore.assets]);
  }

  public updateCache(assets: AtlasAssetModel[], payload: Partial<AtlasAssetModel>) {
    const cachedAssets = this.dataStore.assets;
    const findAndUpdateCachedAsset = asset => {
      const index = cachedAssets.findIndex(a => asset.id === a.id);
      if (index === -1) {
        // asset not found in cache
        return;
      }
      //update cache
      cachedAssets[index] = {...asset, ...payload};
    };
    assets.forEach(findAndUpdateCachedAsset);

    this._assets.next([...this.dataStore.assets]);
  }

  public toggleMap(map: Map, layers: any, mapView) {
    const mapViewMapping = {
      [MAP_VIEW.SATELLITE]: {
        layer: layers.satelliteView,
        nextView: {type: MAP_VIEW.HYBRID, layer: layers.hybridView}
      },
      [MAP_VIEW.HYBRID]: {layer: layers.hybridView, nextView: {type: MAP_VIEW.ROADMAP, layer: layers.roadView}},
      [MAP_VIEW.ROADMAP]: {layer: layers.roadView, nextView: {type: MAP_VIEW.SATELLITE, layer: layers.satelliteView}}
    };

    const currentView = mapViewMapping[mapView];
    map.removeLayer(currentView.layer);
    map.addLayer(currentView.nextView.layer);
    return currentView.nextView.type;
  }

  public addFeatureProperties(feature, asset) {
    feature.asset = asset;
    feature.properties = feature.properties || {};
    feature.properties.featureColor = this.getFeatureColorAttribution(feature);
  }

  public deleteFeatureCustomProperties(feature) {
    delete feature.asset;
    delete feature.properties?.featureColor;
    delete feature.properties?.selectedIcon;
  }

  public getFeatureColorAttribution(marker) {
    const properties: any = marker?.properties;
    if (!properties) {
      return null;
    }

    const state = properties.state || marker.state;
    if (state) {
      return this.getStatusMarkerColor(state);
    }

    if (properties.boundingBox) {
      return properties.color || defaultHouseMarkerColor;
    }

    if (properties.iconHref) {
      return null;
    }

    return marker.asset?.color || marker.properties?.color || atlasConfig.defaultMarkerColor;
  }

  public getStatusMarkerColor(state: GeojsonFeatureState) {
    const colorMap = {
      [GeojsonFeatureState.IN_PROGRESS]: StatusMarkerColors.IN_PROGRESS,
      [GeojsonFeatureState.COMPLETED]: StatusMarkerColors.COMPLETED,
      [GeojsonFeatureState.NOT_COMPLETED]: StatusMarkerColors.NOT_COMPLETED
    };

    return colorMap[state] || StatusMarkerColors.IN_PROGRESS;
  }

  public getAllPointFeatures(): GeoJSONFeature[] {
    return this.assets
      .reduce((acc, asset) => {
        let geojsonFeatures = (asset.geojson as any)?.features || [];
        geojsonFeatures = geojsonFeatures.map((feature, i) => {
          feature.index = i;
          const featureCopy = {...feature};
          this.addFeatureProperties(featureCopy, asset);
          return featureCopy;
        });
        return acc.concat(geojsonFeatures);
      }, [])
      .filter(feature => feature.geometry?.type === 'Point');
  }
}
