  <mat-card-header>
    <div class="header">
      <form [formGroup]="form">
        <unleash-search-input
          formControlName="search"
          [form]="form"
          (search)="performSearch($event)"
        ></unleash-search-input>
      </form>
      <button (click)="emitOpenInviteUsersDialog()" data-test-id="invite-users-button" mat-button color="primary">
        <mat-icon>group_add</mat-icon> {{ 'profile.users.inviteUsers' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <unleash-users-table
        [dataSource]="users"
        [menuItems]="menuItems"
        [hasMoreThanOneAdmin]="hasMoreThanOneAdmin"
        [hasToHideRole]="true"
        [roles]="roles"
        (user)="emitUser($event)"
        (action)="emitAction($event)"
        (clearFilter)="clearSearch()"
      ></unleash-users-table>
    </div>
  </mat-card-content>