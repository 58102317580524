import {Injectable} from '@angular/core';
import {Marker} from 'leaflet';
import {BehaviorSubject, combineLatest, Observable, of, distinctUntilChanged, zip} from 'rxjs';
import {filter, map, mergeMap, share, switchMap, take} from 'rxjs/operators';
import {RouteCreator} from '../components/create-mission/route-creator';
import {
  BelongsToTeam,
  HeightMode,
  MissionModel,
  MissionPoint,
  MissionPointType,
  MissionPositioningType,
  MissionType,
  TemplateFolderDTO
} from '@app/atlas/model/mission.model';
import {cloneDeep} from 'lodash';
import {
  MissionActionWithParam,
  MissionConfig,
  MissionFolderDTO,
  MissionSurveySettings
} from '../model/survey-mission.model';
import {AtlasService} from './atlas.service';

@Injectable({
  providedIn: 'root'
})
/** Frontend-only service to store settings for mission control*/
export class MissionSettingsService {
  public newVertexDrawed: BehaviorSubject<{waypoint: MissionRoutePoint; distance: number; time: number}> =
    new BehaviorSubject(null);
  public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // TODO: bad practice to use getters for observables
  get totalDistance$(): Observable<number> {
    return this.routeCreatorInitialized$.pipe(
      switchMap((val: boolean) => (!val ? of(0) : this.routeCreator.totalDistance$))
    );
  }

  get isMapClean$(): Observable<boolean> {
    return this._isMapClean.asObservable();
  }

  get isEditing$(): Observable<boolean> {
    return this._isEditing$.asObservable();
  }

  get selectedMarker(): Observable<Marker | undefined> {
    return this._selectedMarker$.asObservable().pipe(share());
  }

  get settings$(): Observable<MissionRoutePoint> {
    return this._settings$.asObservable().pipe(share());
  }

  get markerSettings$(): Observable<MissionRoutePoint> {
    return this.getSelectedMarkerSettings().pipe(share());
  }

  /** Aggregate all markers from route creator to build the route using
   *  - global settings observable
   *  - point-based settings observable
   *  - marker values observable
   *  - route creator initializer indicator observable (helper)
   *  */
  get route(): Observable<MissionRoutePoint[]> {
    /* it's necessary to use helper observable routeCreatorInitialized,
     * to wait for routeCreator initialization
     * once routeCreator is ready (map is ready) then
     * switch source and progress with route generation
     * */

    // listen to settings changes
    const settingChange = combineLatest(this._settingsByMarker$, this._settings$);
    return this.routeCreatorInitialized$.pipe(
      switchMap((val: boolean) =>
        !val
          ? []
          : settingChange.pipe(
              mergeMap(() => this.routeCreator.markers$),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              map((markers: (Marker | any)[]) => markers.map(this.buildRouteJSON.bind(this)))
            )
      )
    );
  }

  private defaultSettings: MissionRoutePoint = new MissionRoutePoint(undefined, undefined);
  private _settings$: BehaviorSubject<MissionRoutePoint> = new BehaviorSubject(this.defaultSettings);
  private _settingsByMarker$: BehaviorSubject<SettingsByMarker> = new BehaviorSubject({});
  private _selectedMarker$: BehaviorSubject<Marker | undefined> = new BehaviorSubject(undefined);
  private routeCreatorInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _currentMission$: BehaviorSubject<Mission> = new BehaviorSubject(null);
  public currentMission$ = this._currentMission$.asObservable();
  public editingMission: BehaviorSubject<{mission: Mission; selectedWaypointIndex?: number}> = new BehaviorSubject(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public routeCreator: RouteCreator;

  private _isEditing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isMapClean: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public hasToRedraw: boolean = false;
  public hasToRegenerateVertex: boolean = false;
  public indexToRemove: number = -1;
  public firstMissionState: Mission = null;
  public undoStack: BehaviorSubject<Mission[]> = new BehaviorSubject<Mission[]>([]);
  public redoStack: BehaviorSubject<Mission[]> = new BehaviorSubject<Mission[]>([]);
  public selectedWaypointIndex: number = SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED;
  public isNewRoute: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUpdatingMission: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private atlasService: AtlasService) {
    this.currentMission$
      .pipe(
        filter((mission: Mission) => !!mission),
        take(1)
      )
      .subscribe((mission: Mission) => {
        this.firstMissionState = cloneDeep(mission);
      });

    combineLatest([this._currentMission$, this.routeCreatorInitialized$])
      .pipe(
        filter(([mission, isRouterCreatorInitializad]) => isRouterCreatorInitializad),
        map(([mission, isRouterCreatorInitializer]) => mission),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((mission: Mission) => {
        this.atlasService.setHasToSkipFitBoundsOnLoadLayers(true);
        this.setEditingMission({mission: mission, selectedWaypointIndex: SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED});
        if (mission && mission.type === MissionType.MAPPING_2D) {
          this.routeCreator.loadMission({mission, hasToClearLayers: true, hasToZoomIn: true});
          this._isMapClean.next(false);
          return;
        }
        if (!mission || !mission?.route?.every(routeItem => !!routeItem) || (mission as any).isNewMission) {
          return;
        }
        this.routeCreator.loadMission({mission, hasToClearLayers: true, hasToZoomIn: true});
        this._isMapClean.next(false);
      });

    this.editingMission
      .pipe(
        map(editMission => editMission?.mission),
        filter(mission => !!mission)
      )
      .subscribe(mission => {
        const missionSet = [...this.undoStack.value, cloneDeep(mission)];
        //avoid duplicated values
        this.undoStack.next([...new Map(missionSet.map(item => [JSON.stringify(item['route']), item])).values()]);
      });
  }

  public viewLocation(mission: Mission): void {
    if (mission && mission.type === MissionType.MAPPING_2D) {
      this.routeCreator.loadMission({mission, hasToClearLayers: true, hasToZoomIn: true});
    }
    if (!mission || !mission?.route?.every(routeItem => !!routeItem)) {
      return;
    }
    this.routeCreator.loadMission({mission, hasToClearLayers: true, hasToZoomIn: true});
  }

  public setIsUpdatingMission(isUpdatingMission: boolean): void {
    this.isUpdatingMission.next(isUpdatingMission);
  }

  public undo(currentState: Mission) {
    const undoStack = this.undoStack.value;
    this.redoStack.next([...this.redoStack.value, currentState]);
    undoStack.pop();
    const previousState = undoStack[undoStack.length - 1] || this.firstMissionState;
    this.resetEditMode(previousState, this.selectedWaypointIndex);
  }

  public redo(currentState: Mission) {
    const redoStack = this.redoStack.value;
    this.undoStack.next([...this.undoStack.value, currentState]);
    const nextState = redoStack.pop();
    if (redoStack.length === 0) {
      this.redoStack.next([]);
    }
    if (nextState) {
      this.resetEditMode(nextState, this.selectedWaypointIndex);
    }
  }

  public revertAllChanges(): void {
    this.resetEditMode(this.undoStack.value[0], this.selectedWaypointIndex);
    this.undoStack.next([this.undoStack.value[0]]);
    this.redoStack.next([]);
  }

  public resetEditMode(mission: Mission, selectedWaypointIndex?: number): void {
    this.setEditingMission({mission, selectedWaypointIndex});
    this.routeCreator.editStop();
    this.routeCreator.loadMission({mission, hasToClearLayers: true, hasToZoomIn: true});
    this.editRoute();
  }

  public sethasToRedraw(hasToRedraw: boolean) {
    this.hasToRedraw = hasToRedraw;
  }

  public setHasToRegenerateVertex(hasToRegenerateVertex: boolean) {
    this.hasToRegenerateVertex = hasToRegenerateVertex;
  }

  public setIsEditingMission(isEditingMission: boolean) {
    this.isEditing.next(isEditingMission);
  }

  public newRoute(): void {
    this.routeCreator.newRoute();
    this.setIsEditingMission(true);
    this.isNewRoute.next(true);
  }

  public clearRoute(): void {
    this.routeCreator.editSave();
    this.routeCreator.clear();
    this._isMapClean.next(true);
    this.newRoute();
  }

  public removeRoute(): void {
    this.routeCreator.editSave();
    this.routeCreator.clear();
    this.setIsEditingMission(false);
  }

  public editRoute(): void {
    this.routeCreator.editRoute();
    this.setIsEditingMission(true);
  }

  public editSettings(): void {
    if (!this.isEditing.value) {
      this.routeCreator.editSettings();
    }
  }

  public editSave(): void {
    const saved = this.routeCreator.editSave();
    this.editStop(!saved);
  }

  public stopDrawing(): void {
    this.routeCreator.stopDrawing();
  }

  public editStop(isClean: boolean = false): void {
    this.setIsEditingMission(false);
    if (!isClean) {
      // handle situation when stopping editng and no markers are drawn
      this._isMapClean.next(false);
    }
  }

  public setMission(mission: Mission): void {
    this._currentMission$.next(mission);
  }

  public setEditingMission(newMission: {mission: Mission; selectedWaypointIndex?: number}): void {
    this.editingMission.next(newMission);
  }

  /** Save settings for all markers*/
  public saveSettings(value: MissionRoutePoint): void {
    this._settings$.next(value);
  }

  public updateSettings(value: Partial<MissionRoutePoint>): void {
    this._settings$.next({...this._settings$.value, ...value});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addRouteCreator(routeCreator: RouteCreator): void {
    this.routeCreator = routeCreator;
    this.routeCreatorInitialized$.next(true);
  }

  public selectMarker(marker: Marker): void {
    this._selectedMarker$.next(marker);
  }

  public deselectMarker(): void {
    this._selectedMarker$.next(undefined);
  }

  public setSelectedWaypointIndex(waypointIndex: number): void {
    this.selectedWaypointIndex = waypointIndex;
  }
  /** Get settings for currently selected marker*/
  public getSelectedMarkerSettings(): Observable<MissionRoutePoint> {
    return this._selectedMarker$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((marker: Marker | any) => {
        if (!marker) {
          return null;
        }
        return this.getSettingsByMarker(marker._leaflet_id) || this._settings$.getValue();
      }),
      share()
    );
  }

  /** Save settings for currently selected marker*/
  public saveSelectedMarkerSettings(settings: MissionRoutePoint): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedMarker: Marker | any = this._selectedMarker$.getValue();
    if (!selectedMarker) {
      throw new Error('saveSelectedMarkerSettings: No marker selected.');
    }
    this.saveMarkerSettings(selectedMarker, settings);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveMarkerSettings(marker: Marker | any, settings: MissionRoutePoint): void {
    const settingsByMarker = this._settingsByMarker$.getValue();
    settingsByMarker[marker._leaflet_id] = {...settings};
    this._settingsByMarker$.next({...settingsByMarker});
  }

  public selectRoutePoint(waypointIndex: number): void {
    this.routeCreator.selectRoutePoint(waypointIndex);
  }

  public deselectRoutePoint(waypointIndex: number): void {
    this.routeCreator.deselectRoutePoint(waypointIndex);
  }

  public deselectRouteEditPoint(waypointIndex: number): void {
    this.routeCreator.deselectRouteEditPoint(waypointIndex);
  }

  /** @param markerId - leaflet id of the marker
   * @return if settings had been assigned to a marker then return them, otherwise default settings */
  private getSettingsByMarker(markerId: number): MissionRoutePoint {
    return this._settingsByMarker$.getValue()[markerId];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private buildRouteJSON(marker: Marker | any): MissionRoutePoint {
    const markerSettings = this.getSettingsByMarker(marker._leaflet_id);
    const latLng = marker.getLatLng();
    const altitude = markerSettings?.altitude;
    const speed = markerSettings?.speed;
    const heading = markerSettings.heading;
    const pitch = markerSettings?.pitch;
    const lat = latLng.lat;
    const lng = latLng.lng;
    return new MissionRoutePoint(lat, lng, altitude, speed, heading, pitch);
  }
}

export class Mission implements MissionModel, BelongsToTeam {
  public id?: string;
  public createdAt?: number;
  public updatedAt?: number;
  public ownerId?: string;
  public teamId: string;
  public companyId: string;
  public time?: number;
  public distance?: number;
  public lastFlight?: number;
  public description?: string; // only smart inspect
  public heightMode?: HeightMode;
  public speed?: number;
  public type?: MissionType;
  public isCorridorMission?: boolean;
  public surveySettings?: MissionSurveySettings;
  public takeOffRefPointLatitude?: number;
  public takeOffRefPointLongitude?: number;
  public takeOffSecurityHeight?: number;
  public missionConfig?: MissionConfig;
  public positioningType?: MissionPositioningType;
  public waylineFolders: MissionFolderDTO[];
  public templateFolder: TemplateFolderDTO;

  constructor(
    public name: string,
    public route?: MissionRoutePoint[],
    public isImported?: boolean,
    public isSmartInspect?: boolean,
    public settings?: MissionModel
  ) {
    Object.assign(this, settings);
  }

  public validate?(): void;
}

// eslint-disable-next-line max-classes-per-file
export class MissionRoutePoint implements MissionPoint {
  constructor(
    public lat: number = undefined,
    public lng: number = undefined,
    public altitude: number = DefaultMissionSettings.DEFAULT_ALT,
    public speed: number = DefaultMissionSettings.DEFAULT_SPEED,
    public heading: number = DefaultMissionSettings.DEFAULT_HEADING,
    public pitch: number = DefaultMissionSettings.DEFAULT_PITCH,
    public actions?: (MissionActionWithParam | null)[],
    public altitudeWGS?: number,
    public altitudeEGM?: number,
    public gimbal: number = DefaultMissionSettings.DEFAULT_GIMBAL,
    public type?: MissionPointType,
    public label?: string,
    public index?: number
  ) {}
}

// eslint-disable-next-line max-classes-per-file
export enum DefaultMissionSettings {
  DEFAULT_SPEED = 2,
  DEFAULT_ALT = 60,
  DEFAULT_HEADING = 0,
  DEFAULT_PITCH = 0,
  DEFAULT_GIMBAL = 0
}

export interface SettingsByMarker {
  [leafletId: number]: MissionRoutePoint;
}

export enum SELECTED_WAYPOINT {
  NO_WAYPOINT_SELECTED = -1,
  DEFAULT_WAYPOINT = 0
}
