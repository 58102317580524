import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Roles, UserAction, UserTable, UserTableActions} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent {
  @Input() public form: FormGroup = new FormGroup({
    search: new FormControl('')
  });
  @Input() public users: UserTable[] = [];
  @Input() public menuItems: UserTableActions[] = [];
  @Input() public hasMoreThanOneAdmin: boolean = false;
  @Input() public roles: Roles = {};
  @Input() public isAddUserMode: boolean = true;
  @Output() public searchUser: EventEmitter<string> = new EventEmitter();
  @Output() public user: EventEmitter<UserTable> = new EventEmitter();
  @Output() public action: EventEmitter<UserAction> = new EventEmitter();
  @Output() public openInviteUsersDialog: EventEmitter<void> = new EventEmitter();

  public performSearch(search: string): void {
    this.searchUser.emit(search);
  }

  public emitUser(user: UserTable): void {
    this.user.emit(user);
  }

  public emitAction(action: UserAction): void {
    this.action.emit(action);
  }

  public clearSearch(): void {
    this.searchUser.emit('');
  }

  public emitOpenInviteUsersDialog(): void {
    this.openInviteUsersDialog.emit();
  }
}
