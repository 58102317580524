import {Component} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {Observable, shareReplay} from 'rxjs';

@Component({
  selector: 'unleash-authorization-tile',
  templateUrl: './authorization-tile.component.html',
  styleUrls: ['./authorization-tile.component.scss']
})
export class AuthorizationTileComponent {
  public aclPermissions = AclPermissions;
  public isMfaEnabled$: Observable<boolean> = this.userStoreFacadeService.isMfaEnabled$.pipe(shareReplay(1));
  public companyId$: Observable<string> = this.userStoreFacadeService.companyId$.pipe(shareReplay(1));

  constructor(private userStoreFacadeService: UserStoreFacadeService) {}
}
