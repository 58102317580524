import {Component} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserService} from '@app/core/services/api/user.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, firstValueFrom, map, Observable, shareReplay, tap} from 'rxjs';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss']
})
export class StreamingComponent {
  public isShared = false;
  public company$: Observable<CompanyModel> = this.userService.myCompany$.pipe(
    tap(company => {
      this.isShared = !!company.isPublicWatchPageEnabled;
      this.publicWatchPageURL = this.getWatchPageLink(company.slug);
    }),
    shareReplay(1)
  );

  public publicWatchPageURL: string;
  public getSecureWatchPageURL: string;
  public isSlugLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public aclPermissions = AclPermissions;
  public myDevices$: Observable<UserDeviceJoined[]> = this.userService.userDevices$.pipe(
    map(userDevices => Object.values(userDevices))
  );

  constructor(
    private userService: UserService,
    public snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  public async updateCompanySlug() {
    const company = await firstValueFrom(this.company$);
    this.isSlugLoaded.next(false);
    this.userService.updateCompany(company.id, {slug: this.userService.generateCompanySlug(company.slug)});
    this.isSlugLoaded.next(true);
    this.snackBar.open('Secure url created successfully!!', 'OK', {
      duration: 4000
    });
  }

  public getWatchPageLink(slug: string) {
    return environment.STREAM_DASHBOARD_URL + slug;
  }

  public async toogleShareBroadcast() {
    this.isShared = !this.isShared;
    const company = await firstValueFrom(this.company$);
    if (!company) {
      return;
    } // todo handle lack of company in ui
    this.userService.updateCompany(company.id, {isPublicWatchPageEnabled: this.isShared});
    this.translateService
      .get(`profile.streaming.shareBroadcastToast.${this.isShared ? '0' : '1'}`)
      .subscribe(res => this.snackBar.open(res, null, {duration: 5000} as MatSnackBarConfig));
  }

  public onUrlCopied() {
    this.translateService
      .get('profile.streaming.urlCopied')
      .subscribe(res => this.snackBar.open(res, null, {duration: 5000} as MatSnackBarConfig));
  }
}
