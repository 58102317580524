<div class="container">
  <div class="section-name">{{ 'profile.headers.users' | translate }}</div>
  <div class="content mat-elevation-z5">
    <ng-container [ngSwitch]="userScreen$ | async">
      <ng-container *ngSwitchCase="userTileScreen.users">
        <unleash-users
          [form]="form"
          [users]="users$ | async"
          [menuItems]="menuItems$ | async"
          [hasMoreThanOneAdmin]="hasMoreThanOneAdmin$ | async"
          [roles]="roles$ | async"
          [isAddUserMode]="false"
          (searchUser)="performSearch($event)"
          (user)="user($event)"
          (action)="action($event)"
          (openInviteUsersDialog)="openInviteUsersDialog()"
        ></unleash-users>
      </ng-container>
      <ng-container *ngSwitchCase="userTileScreen.addUsers">
        <unleash-add-user
          (cancel)="cancel()"
          (invite)="invite($event)"
          (back)="cancel()"
          [roles]="roles$ | async"
          [teams]="teams$ | async"
          [currentTeamId]="currentTeamId$ | async"
        ></unleash-add-user>
      </ng-container>
      <ng-container *ngSwitchCase="userTileScreen.userDetails">
        <unleash-user-detail [user]="selectedUser$ | async" (back)="cancel()"></unleash-user-detail>
      </ng-container>
      <ng-container *ngSwitchDefault> </ng-container>
    </ng-container>
  </div>
</div>
