import {Injectable} from '@angular/core';
import * as jobsActions from './jobs.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, catchError, filter, firstValueFrom, map, merge, mergeMap, of, switchMap, take, tap, zip} from 'rxjs';
import {JobsApiService} from '../services/jobs-api.service';
import {JobsFacadeService} from '../services/jobs-facade.service';
import {MatDialog} from '@angular/material/dialog';
import {NewJobDialog} from '../components/new-job-dialog/new-job-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Job, TaskType} from '../models/jobs.models';
import {AddUsersDialog} from '../components/add-users-dialog/add-users-dialog.component';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {TeamRole} from '@app/profile/models/team.model';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {DELETE_JOBS_OPTIONS, DeleteDialog} from '../components/delete-dialog/delete-dialog.component';
import {RENAME_JOBS_OPTIONS, RenameDialog} from '../components/rename-dialog/rename-dialog.component';
import {EditTaskDialog} from '../components/edit-task-dialog/edit-task-dialog.component';
import {taskLayers} from '@app/atlas/atlas.config';
import {NetworkStatusService} from '@app/core/services/network-status.service';

@Injectable()
export class JobsEffects {
  private listJobs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.storeJobs),
        switchMap(() => this.jobsFacadeService.allJobsLoaded$.pipe(take(1))),
        tap((allJobsLoaded: boolean) => {
          if (allJobsLoaded) {
            this.jobsFacadeService.updateIsLoadingJobs(false);
            return;
          }
          this.jobsFacadeService.storeTeamMembers();
        }),
        switchMap(() => this.jobsFacadeService.nextToken$),
        filter(nextToken => nextToken !== undefined),
        switchMap(nextToken =>
          this.jobsApiService.getJobs(nextToken).pipe(
            tap(payload => {
              this.jobsFacadeService.storeJobsSuccess(payload.items, payload.nextToken);
            })
          )
        )
      ),
    {dispatch: false}
  );

  private createNewJob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.createNewJob),
        switchMap(() => {
          const dialog = this.matDialog.open(NewJobDialog, {
            ...STANDARD_DIALOG_CONFIG,
            width: '40vw',
            minWidth: '280px',
            height: '213px'
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter((response: any) => !!response),
            switchMap((response: any) => {
              if (response?.error) {
                return this.translateService.get('jobs.newJobError');
              }
              this.router.navigate(['/secure/jobs/' + response.id]);
              this.jobsFacadeService.createNewJobSuccess(response);
              return this.translateService.get('jobs.newJobSaved');
            }),
            tap(translation => {
              this.snackBar.open(translation, null, {
                duration: 6000,
                panelClass: 'center'
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  private storeSingleJob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.storeSingleJob),
        tap(() => this.jobsFacadeService.storeTeamMembers()),
        switchMap(action =>
          this.jobsApiService.getJob(action.jobId).pipe(
            tap((job: Job) => this.jobsFacadeService.storeSingleJobSuccess(job)),
            catchError(() => {
              this.jobsFacadeService.updateIsLoadingSingleJob(false);
              return EMPTY;
            })
          )
        )
      ),
    {dispatch: false}
  );

  private openAddUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.openAddUsers),
        switchMap(actions =>
          zip(
            of(actions.jobId),
            this.userStoreFacadeService.currentTeam$.pipe(take(1)),
            this.jobsFacadeService.selectJobUsers(actions.jobId).pipe(take(1)),
            this.jobsFacadeService.teamMembers$.pipe(
              take(1),
              map(teamMembers =>
                Object.fromEntries(Object.entries(teamMembers).filter(([key, value]) => value.role !== TeamRole.viewer))
              )
            )
          )
        ),
        switchMap(([jobId, currentTeam, jobAssignedUsers, teamMembers]) => {
          const dialog = this.matDialog.open(AddUsersDialog, {
            ...STANDARD_DIALOG_CONFIG,
            disableClose: true,
            autoFocus: false,
            width: '40vw',
            minWidth: '280px',
            data: {teamName: currentTeam.name, teamMembers, jobAssignedUsers: [...jobAssignedUsers], jobId}
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter((response: any) => !!response),
            switchMap((response: any) => {
              if (response?.error) {
                return this.translateService.get('jobs.usersUpdatedError');
              }
              this.jobsFacadeService.updateJobSuccess(response.jobId, response.data);
              return jobAssignedUsers.length === 0
                ? this.translateService.get('jobs.usersAdded')
                : this.translateService.get('jobs.usersUpdated');
            }),
            tap(translation => {
              this.snackBar.open(translation, null, {
                duration: 6000,
                panelClass: 'center'
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  private storeJobTasks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.storeJobTasks),
        switchMap(action =>
          this.jobsFacadeService.selectJob(action.jobId).pipe(
            filter(job => !!job),
            take(1)
          )
        ),
        switchMap(job => {
          this.jobsFacadeService.resetTaskNextToken();
          return this.jobsFacadeService.taskNextToken$.pipe(
            filter(nextToken => nextToken !== undefined),
            switchMap(nextToken => {
              return this.jobsApiService.getJobTasks(nextToken, job.id).pipe(
                tap(tasks => {
                  this.jobsFacadeService.storeJobTasksSuccess(tasks.items, job.id, tasks.nextToken);
                }),
                catchError(() => {
                  this.translateService
                    .get('jobs.internalError')
                    .pipe(take(1))
                    .subscribe(translation => {
                      this.snackBar.open(translation, null, {
                        panelClass: 'center',
                        duration: 3000
                      });
                    });
                  return EMPTY;
                })
              );
            })
          );
        })
      ),
    {dispatch: false}
  );

  private addTasks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.addTasks),
        switchMap(action => {
          this.jobsFacadeService.updateIsAddingTasks(true);
          const taskPromises = [];
          action.tasks.forEach(task => {
            taskPromises.push(
              firstValueFrom(
                this.atlasService
                  .createEmptyAsset({name: task.name, type: AssetType.GEOJSON, key: '', groupName: taskLayers})
                  .pipe(
                    switchMap(asset => {
                      const ids = task.geojson.features.map(feature => feature?.index);
                      return zip(
                        of(asset),
                        of(ids.length),
                        this.atlasService.copyMarkers(action.selectedAssetId, asset.id, ids)
                      );
                    }),
                    switchMap(([asset, totalMarkers, copyMarkersResponse]) => {
                      this.atlasService.addAssets([{...asset, key: copyMarkersResponse?.destinationAsset?.key}]);
                      return this.jobsApiService.createJobTask({
                        type: TaskType.FLIGHT_REQUEST,
                        title: task.name,
                        assignedId: task.userId,
                        description: '',
                        jobId: action.jobId,
                        context: {
                          asset: {
                            id: asset.id,
                            totalMarkers
                          } as unknown as AtlasAssetModel
                        }
                      });
                    }),
                    catchError(() => {
                      this.translateService
                        .get('jobs.internalError')
                        .pipe(take(1))
                        .subscribe(translation => {
                          this.snackBar.open(translation, null, {
                            panelClass: 'center',
                            duration: 3000
                          });
                        });
                      return EMPTY;
                    })
                  )
              )
            );
          });
          return zip(of(action.jobId), Promise.all(taskPromises));
        }),
        tap(([jobId, _]) => {
          this.jobsFacadeService.updateIsAddingTasks(false);
          this.router.navigate(['/secure/jobs/' + jobId]);
        })
      ),
    {dispatch: false}
  );

  private updateJob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.updateJob),
        switchMap(action => {
          this.jobsFacadeService.updateIsUpdatingJob(true);
          return this.jobsApiService.updateJob(action.jobId, action.params).pipe(
            tap(response => {
              this.jobsFacadeService.updateJobSuccess(action.jobId, response);
              this.jobsFacadeService.updateIsUpdatingJob(false);
            })
          );
        })
      ),
    {dispatch: false}
  );

  private openDeleteJob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.openDeleteJob),
        switchMap(action => {
          const dialog = this.matDialog.open(DeleteDialog, {
            ...STANDARD_DIALOG_CONFIG,
            width: '40vw',
            data: {
              id: action.jobId,
              deleteOption: DELETE_JOBS_OPTIONS.JOB,
              name: action.name,
              assetId: action?.assetId
            }
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter((response: any) => !!response),
            switchMap((response: any) => {
              const error = response.error;
              if (error) {
                const errorMessage = error.message;
                return errorMessage ? of(errorMessage) : this.translateService.get('jobs.deleteJobError');
              }
              this.jobsFacadeService.deleteJobSuccess(response.jobId, action.jobStatus);
              if (action.hasToRedirect) {
                this.router.navigate(['/secure/jobs']);
              }
              return this.translateService.get('jobs.jobDeleted');
            }),
            tap(translation => {
              this.snackBar.open(translation, null, {
                duration: 6000,
                panelClass: 'center'
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  private openDeleteTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.openDeleteTask),
        switchMap(action => {
          const dialog = this.matDialog.open(DeleteDialog, {
            ...STANDARD_DIALOG_CONFIG,
            width: '40vw',
            data: {
              id: action.taskId,
              deleteOption: DELETE_JOBS_OPTIONS.TASK,
              name: action.name
            }
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter((response: any) => !!response),
            switchMap((response: any) => {
              if (response.removeAtlasAssetError) {
                this.jobsFacadeService.deleteTaskSuccess(response.data.id, response.data.jobId);
                return this.translateService.get('jobs.tasks.removeAtlasAssetError');
              }
              if (response?.error) {
                return this.translateService.get('jobs.tasks.deleteTaskError');
              }
              this.jobsFacadeService.deleteTaskSuccess(response.data.id, response.data.jobId);
              return this.translateService.get('jobs.tasks.taskDeleted');
            }),
            tap(translation => {
              this.snackBar.open(translation, null, {
                duration: 6000,
                panelClass: 'center'
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  private openRenameJob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.openRenameJob),
        switchMap(action => {
          const dialog = this.matDialog.open(RenameDialog, {
            ...STANDARD_DIALOG_CONFIG,
            width: '40vw',
            data: {
              jobId: action.jobId,
              renameOption: RENAME_JOBS_OPTIONS.JOB,
              name: action.name
            }
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter((response: any) => !!response),
            switchMap((response: any) => {
              if (response?.error) {
                return this.translateService.get('jobs.renameJobError');
              }
              this.jobsFacadeService.updateJobSuccess(response.jobId, response.data);
              return this.translateService.get('jobs.jobRenamed');
            }),
            tap(translation => {
              this.snackBar.open(translation, null, {
                duration: 6000,
                panelClass: 'center'
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  public updateTask$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.updateTask),
        mergeMap(action =>
          merge(this.jobsApiService.updateTask(action.taskId, action.params), of({...action.params})).pipe(
            tap(response => {
              const params = response.status ? {...action.params, status: response.status} : action.params;
              this.jobsFacadeService.updateTaskSuccess(action.taskId, action.jobId, params);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public openMoreDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.openMoreDetails),
        switchMap(action =>
          zip(
            of(action),
            this.jobsFacadeService.teamMembers$.pipe(take(1)),
            this.jobsFacadeService.selectJobUsers(action.task.jobId).pipe(take(1))
          )
        ),
        switchMap(([action, teamMembers, jobUserIds]) => {
          const dialog = this.matDialog.open(EditTaskDialog, {
            ...STANDARD_DIALOG_CONFIG,
            width: '800px',
            minWidth: '280px',
            height: '100vh',
            position: {right: '0'},
            autoFocus: false,
            data: {
              task: action.task,
              jobUserIds,
              teamMembers,
              jobName: action.jobName
            },
            disableClose: true
          });
          return dialog.afterClosed().pipe(
            filter(data => !!data),
            switchMap(({changes, jobId}) =>
              zip(this.jobsApiService.getJob(jobId).pipe(take(1)), of(changes), of(jobId))
            ),
            tap(([updatedJob, data, jobId]) => {
              this.jobsFacadeService.updateJobSuccess(jobId, updatedJob);
              this.jobsFacadeService.updateTaskSuccess(action.task.id, jobId, data);
            })
          );
        })
      ),
    {dispatch: false}
  );

  public addMissionTasks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.addMissionTasks),
        switchMap(action => {
          this.jobsFacadeService.updateIsAddingTasks(true);
          const taskPromises = [];
          action.tasks.forEach(task => {
            taskPromises.push(
              firstValueFrom(
                this.jobsApiService.createJobTask({
                  type: TaskType.FLIGHT_REQUEST,
                  title: task.name,
                  assignedId: task.userId,
                  description: '',
                  jobId: action.jobId,
                  context: {
                    mission: {
                      id: task?.mission.id
                    }
                  }
                })
              )
            );
          });
          return zip(of(action.jobId), Promise.all(taskPromises));
        }),
        tap(([jobId, _]) => {
          this.jobsFacadeService.updateIsAddingTasks(false);
          this.router.navigate(['/secure/jobs/' + jobId]);
        })
      ),
    {dispatch: false}
  );

  private createFlightPathTasks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.createFlightPathTasks),
        switchMap(action => {
          this.jobsFacadeService.updateIsAddingTasks(true);
          const tasks = action.payload.flightPathTasks;
          const jobId = action.payload.jobId;
          const taskPromises = [];
          tasks.forEach(task => {
            taskPromises.push(
              firstValueFrom(
                this.jobsApiService
                  .createFlightPathTask({
                    context: task.context,
                    name: task.name,
                    route: task.markers,
                    jobId,
                    assignedId: task.userId,
                    type: TaskType.FLIGHT_REQUEST_MANUAL
                  })
                  .pipe(
                    catchError(() => {
                      this.jobsFacadeService.updateIsAddingTasks(false);
                      this.translateService
                        .get(this.networkStatusService.isOnline ? 'jobs.internalError' : 'jobs.network_error')
                        .pipe(take(1))
                        .subscribe(translation => {
                          this.snackBar.open(translation, null, {
                            panelClass: 'center',
                            duration: 3000
                          });
                        });
                      return EMPTY;
                    })
                  )
              )
            );
          });
          return zip(of(jobId), Promise.all(taskPromises));
        }),
        tap(([jobId, _]) => {
          this.translateService
            .get('jobs.tasks.tasksAdded')
            .pipe(take(1))
            .subscribe(translation => {
              this.snackBar.open(translation, null, {
                panelClass: 'center',
                duration: 3000
              });
            });
          this.jobsFacadeService.updateIsAddingTasks(false);
          this.router.navigate(['/secure/jobs/' + jobId]);
        })
      ),
    {dispatch: false}
  );

  private loadJobMarkers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.loadJobMarkers),
        switchMap(action => {
          return zip([
            of(action),
            this.jobsApiService.getS3File(action.payload.job.s3Path).pipe(
              catchError(() => {
                return this.translateService.get(['jobs.s3PathError', 'jobs.refreshPage']).pipe(
                  take(1),
                  switchMap(translations => {
                    this.jobsFacadeService.updateIsLoadingMarkers(false);
                    const {'jobs.s3PathError': s3PathError, 'jobs.refreshPage': refreshPage} = translations;
                    console.error(s3PathError);
                    const snackBarRef = this.snackBar.open(s3PathError, refreshPage, {
                      duration: 6000,
                      panelClass: 'center'
                    });
                    return snackBarRef.onAction().pipe(
                      tap(() => {
                        location.reload();
                      })
                    );
                  })
                );
              })
            )
          ]);
        }),
        map(([action, markerData]) => {
          return this.jobsFacadeService.loadJobMarkersSuccess(
            action.payload.job.id,
            JSON.parse(JSON.stringify(markerData))
          );
        })
      ),
    {dispatch: false}
  );

  private storeTeamMembers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(jobsActions.storeTeamMembers),
        switchMap(() => {
          return this.jobsApiService.getTeamUsers().pipe(
            tap(teamMembers => {
              this.jobsFacadeService.storeTeamMembersSuccess(teamMembers);
            }),
            catchError(() => {
              this.translateService
                .get('jobs.internalError')
                .pipe(take(1))
                .subscribe(translation => {
                  this.snackBar.open(translation, null, {
                    panelClass: 'center',
                    duration: 3000
                  });
                });
              return EMPTY;
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private jobsApiService: JobsApiService,
    private jobsFacadeService: JobsFacadeService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router,
    private userStoreFacadeService: UserStoreFacadeService,
    private atlasService: AtlasService,
    private networkStatusService: NetworkStatusService
  ) {}
}
