import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {Canvas, CanvasImageQuality, DrawStatus, ImageSize} from './models/image-annotation.model';
import {CanvasService} from './services/canvas.service';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let SVG: any;

@Component({
  selector: 'unleash-image-annotation-shared',
  templateUrl: './image-annotation-shared.component.html',
  styleUrls: ['./image-annotation-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageAnnotationSharedComponent implements OnDestroy {
  @Input() public canvasId: string = 'draw-zones';
  @ViewChild('container', {static: false}) public container: ElementRef<HTMLCanvasElement>;
  @ViewChild('drawZones', {static: false}) public drawZonesContainer: ElementRef<HTMLCanvasElement>;

  @ViewChild('imgRef', {static: false}) public imgRef: ElementRef<HTMLImageElement>;
  @ViewChild('imgRefLowRes', {static: false}) public imgRefLowRes: ElementRef<HTMLImageElement>;
  @ViewChild('imgRefMidRes', {static: false}) public imgRefMidRes: ElementRef<HTMLImageElement>;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('src')
  public set setupSrc(src: string) {
    this.isHiResVisible = false;
    this.isLowResVisible = false;
    this.isMidResVisible = false;

    if (/null$/.test(src)) {
      return;
    }
    this.src = src;
  }
  @Input() public srcLowRes: string;
  @Input() public srcMidRes: string;
  @Input() public zoom: number = 1;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('isMobileView') public set setupIsMobileView(isMobileView: boolean) {
    if (isMobileView) {
      // this.clearCrossLine();
    }
  }
  @Input() public drawStatus: DrawStatus;
  @Input('isHoveringShape') public set setupIsHoveringShape(value: any) {}
  @Input() public maskImg: string;
  @Input() public isMouseMoveActive: boolean;
  @Input() public imgHoverMask: string;

  @Output() public loaded: EventEmitter<boolean> = new EventEmitter();
  @Output() public canvasLoaded: EventEmitter<CanvasImageQuality> = new EventEmitter();
  @Output() public lowResLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() public hideAnnotationLabels: EventEmitter<void> = new EventEmitter();
  @Output() public showAnnotationLabels: EventEmitter<void> = new EventEmitter();
  @Output() public mouseOverEvent: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public mouseMoveEvent: EventEmitter<{x: number; y: number}> = new EventEmitter();
  @Output() public mouseLeaveEvent: EventEmitter<void> = new EventEmitter();

  public imageSize: ImageSize = {width: null, height: null};
  public src: string;
  public isHiResVisible: boolean = false;
  public isLowResVisible: boolean = false;
  public isMidResVisible: boolean = false;
  public canvasImageQuality = CanvasImageQuality;
  public hasToDisplayPhosphorescent = false;

  constructor(private canvasService: CanvasService) {}

  public ngOnDestroy(): void {
    if (this.canvasService.hasCanvas()) {
      this.canvasService.getCanvas().off('mousemove');
      this.canvasService.getCanvas().off('mouseleave');
      this.container.nativeElement.removeEventListener('mousemove', () => null);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setupCanvas(img: HTMLImageElement, canvasImageQuality: CanvasImageQuality): void {
    this.imageSize = {
      width: img.naturalWidth,
      height: img.naturalHeight
    };

    this.canvasService.setNaturalImageDiagonalWidth(this.imageSize.width, this.imageSize.height);

    if (!this.canvasService.hasCanvas()) {
      this.canvasService.setCanvas(new SVG(this.canvasId) as Canvas);
    }

    this.canvasService.canvasSize = this.imageSize;
    this.canvasService.originalSize = this.imageSize;
    this.canvasService.setCanvasViewport(this.imageSize);

    this.drawZonesContainer.nativeElement.style.height = `${this.imageSize.height}px`;
    this.drawZonesContainer.nativeElement.style.width = `${this.imageSize.width}px`;

    const imgRef = canvasImageQuality === CanvasImageQuality.lowRes ? this.imgRefLowRes : this.imgRef;

    // Resize two times to get the new size of image using the available size
    if (canvasImageQuality === CanvasImageQuality.lowRes) {
      this.drawZonesContainer.nativeElement.style.height = '100%';
      this.drawZonesContainer.nativeElement.style.width = '100%';
      this.drawZonesContainer.nativeElement.style.maxHeight = `${this.container.nativeElement.offsetHeight}px`;
      this.drawZonesContainer.nativeElement.style.maxWidth = `${this.container.nativeElement.offsetWidth}px`;
      this.drawZonesContainer.nativeElement.style.maxHeight = `${imgRef.nativeElement.offsetHeight}px`;
      this.drawZonesContainer.nativeElement.style.maxWidth = `${imgRef.nativeElement.offsetWidth}px`;
    } else {
      this.drawZonesContainer.nativeElement.style.maxHeight = `${this.container.nativeElement.offsetHeight}px`;
      this.drawZonesContainer.nativeElement.style.maxWidth = `${this.container.nativeElement.offsetWidth}px`;
      this.drawZonesContainer.nativeElement.style.maxHeight = `${imgRef.nativeElement.offsetHeight}px`;
      this.drawZonesContainer.nativeElement.style.maxWidth = `${imgRef.nativeElement.offsetWidth}px`;
    }

    const {width, height, top, left} = this.getObjectFitSize(
      false,
      imgRef.nativeElement.offsetWidth,
      imgRef.nativeElement.offsetHeight,
      img.naturalWidth,
      img.naturalHeight
    );

    this.drawZonesContainer.nativeElement.style.top = `${top}px`;
    this.drawZonesContainer.nativeElement.style.left = `${left}px`;

    this.canvasService.setCanvasSize({width, height, top, left});
    this.loaded.emit(true);

    if (canvasImageQuality === CanvasImageQuality.hiRes) {
      this.canvasLoaded.emit(canvasImageQuality);
      this.isHiResVisible = true;
      return;
    }

    if (canvasImageQuality === CanvasImageQuality.lowRes) {
      this.isLowResVisible = true;
      return;
    }

    if (canvasImageQuality === CanvasImageQuality.midRes) {
      this.isMidResVisible = true;
      return;
    }
  }

  public resizeChanges(): void {
    if (!this.drawZonesContainer) {
      return;
    }

    const imgRef = this.isHiResVisible ? this.imgRef : this.imgRefLowRes;

    this.drawZonesContainer.nativeElement.style.maxHeight = `${this.container.nativeElement.offsetHeight}px`;
    this.drawZonesContainer.nativeElement.style.maxWidth = `${this.container.nativeElement.offsetWidth}px`;

    this.drawZonesContainer.nativeElement.style.maxHeight = `${imgRef.nativeElement.offsetHeight}px`;
    this.drawZonesContainer.nativeElement.style.maxWidth = `${imgRef.nativeElement.offsetWidth}px`;

    const {width, height, top, left} = this.getObjectFitSize(
      true,
      imgRef.nativeElement.offsetWidth,
      imgRef.nativeElement.offsetHeight,
      imgRef.nativeElement.naturalWidth,
      imgRef.nativeElement.naturalHeight
    );

    this.canvasService.setCanvasSize({width, height, top, left});
  }

  /**
   * Calc the size and position of canvas based on object-fit: contain
   * From
   * https://stackoverflow.com/questions/37256745/object-fit-get-resulting-dimensions#answer-37269418
   */
  public getObjectFitSize(
    contains: boolean,
    containerWidth: number,
    containerHeight: number,
    width: number,
    height: number
  ): {
    width: number;
    height: number;
    left: number;
    top: number;
  } {
    const doRatio = width / height;
    const cRatio = containerWidth / containerHeight;
    let targetWidth = 0;
    let targetHeight = 0;
    const test = contains ? doRatio > cRatio : doRatio < cRatio;

    if (test) {
      targetWidth = containerWidth;
      targetHeight = targetWidth / doRatio;
    } else {
      targetHeight = containerHeight;
      targetWidth = targetHeight * doRatio;
    }

    const HALF = 2;

    const possibleLeft = (containerWidth - targetWidth) / HALF;
    const left = possibleLeft < 0 ? 0 : possibleLeft;

    return {
      width: targetWidth,
      height: targetHeight,
      left: left,
      top: 0
    };
  }

  public emitLowResLoaded(): void {
    this.loaded.emit(true);
    this.lowResLoaded.emit(true);
  }

  public hideLowResImageOnFail(): void {
    this.isLowResVisible = false;
  }

  public hideMidResImageOnFail(): void {
    this.isMidResVisible = false;
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Alt') {
      event.preventDefault();
      this.hideAnnotationLabels.emit();
    }
  }

  @HostListener('window:keyup', ['$event'])
  public keyEventUp(event: KeyboardEvent): void {
    if (event.key === 'Alt') {
      this.showAnnotationLabels.emit();
    }
  }

  public imageResizeOnZoom(zoom: number): void {
    if (!this.imgRef.nativeElement.clientWidth || !this.imgRef.nativeElement.clientHeight || !zoom) {
      return;
    }

    this.zoom = zoom;

    this.canvasService.setCanvasDiagonalWidth(
      zoom * this.imgRef.nativeElement.clientWidth,
      zoom * this.imgRef.nativeElement.clientHeight
    );
  }

  public emitMouseOverEvent(event: MouseEvent): void {
    const realWidth = this.imgRefMidRes.nativeElement.width;
    const displayedWidth = this.imgRefMidRes.nativeElement.offsetWidth;
    const scalingFactor = realWidth / displayedWidth;
    const x = event.offsetX * scalingFactor;
    const y = event.offsetY * scalingFactor;
    this.mouseOverEvent.emit({clientX: x, clientY: y} as MouseEvent);
  }

  public emitMouseMoveEvent(event: {x: number; y: number}): void {
    const realWidth = this.imgRefMidRes.nativeElement.width;
    const displayedWidth = this.imgRefMidRes.nativeElement.offsetWidth;
    const scalingFactor = realWidth / displayedWidth;
    const x = event.x * scalingFactor;
    const y = event.y * scalingFactor;
    this.mouseMoveEvent.emit({x, y});
  }

  public emitMouseLeaveEvent(): void {
    this.mouseLeaveEvent.emit();
  }
}
