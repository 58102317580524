<div class="container">
  <div class="section-name">{{ 'profile.headers.teams' | translate }}</div>
  <div class="content mat-elevation-z5">
    <ng-container [ngSwitch]="teamScreen$ | async">
      <ng-container *ngSwitchCase="teamTileScreen.teams">
        <unleash-teams
          [teams]="teams$ | async"
          [menuItems]="menuItems"
          (addTeam)="addTeam()"
          (searchTeam)="performSearch($event)"
          (team)="team($event)"
          (action)="action($event)"
        ></unleash-teams>
      </ng-container>
      <ng-container *ngSwitchCase="teamTileScreen.addTeam">
        <unleash-add-team (cancel)="cancel()" (createTeam)="createTeam($event)" (back)="cancel()"></unleash-add-team>
      </ng-container>
      <ng-container *ngSwitchCase="teamTileScreen.teamDetails">
        <unleash-team-detail
          [team]="selectedTeam$ | async"
          [roles]="roles$ | async"
          [userBySelectedTeam]="userBySelectedTeam$ | async"
          (back)="cancel()"
          (openAddUsersToTeam)="openAddUsersToTeam()"
          (action)="action($event)"
          (userTeamAction)="userTeamAction($event)"
          (userAction)="userAction($event)"
        ></unleash-team-detail>
      </ng-container>
      <ng-container *ngSwitchDefault> </ng-container>
    </ng-container>
  </div>
</div>
