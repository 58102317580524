import {Component, ChangeDetectionStrategy} from '@angular/core';
import {TeamTileScreen} from '@app/profile/models/team-tile-screen.model';
import {Team, TeamAction, TeamTable, TeamTableActions} from '@app/profile/models/team.model';
import {UserTeamAction} from '@app/profile/models/user-team.model';
import {Roles, UserTable} from '@app/profile/models/users-table.model';
import {TeamsAccountManagementService} from '@app/profile/services/teams-account-management.service';
import {UserTeamsAccountManagementService} from '@app/profile/services/user-teams-account-management.service';
import {UsersAccountManagementService} from '@app/profile/services/users-account-management.service';
import {Observable, shareReplay} from 'rxjs';

@Component({
  selector: 'unleash-teams-tile',
  templateUrl: './teams-tile.component.html',
  styleUrls: ['./teams-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamsTileComponent {
  public teams$: Observable<TeamTable[]> = this.teamsAccountManagementService.teamsTable$.pipe(shareReplay(1));
  public selectedTeam$: Observable<TeamTable | null> = this.teamsAccountManagementService.selectedTeamTable$.pipe(
    shareReplay(1)
  );
  public menuItems: TeamTableActions[] = this.teamsAccountManagementService.teamMenuActions;
  public teamScreen$: Observable<TeamTileScreen> = this.teamsAccountManagementService.teamScreen$.pipe(shareReplay(1));
  public userBySelectedTeam$: Observable<UserTable[]> = this.teamsAccountManagementService.userBySelectedTeam$.pipe(
    shareReplay(1)
  );
  public roles$: Observable<Roles> = this.userAccountManagementService.roles$;

  public teamTileScreen = TeamTileScreen;

  constructor(
    private teamsAccountManagementService: TeamsAccountManagementService,
    private userAccountManagementService: UsersAccountManagementService,
    private userTeamsAccountManagementService: UserTeamsAccountManagementService
  ) {}

  public performSearch(search: string): void {
    this.teamsAccountManagementService.filterTeams(search);
  }

  public addTeam(): void {
    this.teamsAccountManagementService.displayAddTeamsView();
  }

  public createTeam(name: Team['name']): void {
    this.teamsAccountManagementService.addTeam(name);
    this.teamsAccountManagementService.displayTeamsView();
  }

  public cancel(): void {
    this.teamsAccountManagementService.displayTeamsView();
  }

  public team(team: TeamTable): void {
    this.teamsAccountManagementService.selectTeam(team);
  }

  public action(action: TeamAction): void {
    this.teamsAccountManagementService.executeTeamAction(action);
  }

  public openAddUsersToTeam(): void {
    this.teamsAccountManagementService.openAddUsersToTeam();
  }

  public userTeamAction(action: UserTeamAction): void {
    this.userTeamsAccountManagementService.executeUserTeamsAction(action);
  }

  public userAction(action): void {
    this.userAccountManagementService.executeUserAction(action);
  }
}
