import {createFeatureSelector, createSelector} from '@ngrx/store';
import {JobsState, jobsFeatureKey} from './jobs.reducer';

export const selectJobsData = createFeatureSelector<JobsState>(jobsFeatureKey);
export const selectInProgressJobs = createSelector(selectJobsData, (state: JobsState) =>
  state.filteredJobs.inProgress.map(inProgressJobId => state.jobs[inProgressJobId])
);
export const selectCompleteJobs = createSelector(selectJobsData, (state: JobsState) =>
  state.filteredJobs.complete.map(completeJobId => state.jobs[completeJobId])
);
export const selectDraftJobs = createSelector(selectJobsData, (state: JobsState) =>
  state.filteredJobs.draft.map(draftJobId => state.jobs[draftJobId])
);
export const selectPublishedJobs = createSelector(selectJobsData, (state: JobsState) =>
  state.filteredJobs.published.map(publishedJobId => state.jobs[publishedJobId])
);
export const selectFailedJobs = createSelector(selectJobsData, (state: JobsState) =>
  state.filteredJobs.failed.map(failedJobId => state.jobs[failedJobId])
);
export const selectNextToken = createSelector(selectJobsData, (state: JobsState) => state.nextToken);
export const selectTasksNextToken = createSelector(selectJobsData, (state: JobsState) => state.nextTokenTasks);
export const selectIsLoadingJobs = createSelector(selectJobsData, (state: JobsState) => state.isLoadingJobs);
export const selectIsLoadingMarkers = createSelector(selectJobsData, (state: JobsState) => state.isLoadingMarkers);
export const selectIsLoadingSingleJob = createSelector(selectJobsData, (state: JobsState) => state.isLoadingSingleJob);
export const selectHasJobs = createSelector(selectJobsData, (state: JobsState) => Object.values(state.jobs).length > 0);
export const selectJob = (jobId: string) =>
  createSelector(selectJobsData, (state: JobsState) => {
    return state.jobs[jobId];
  });
export const selectTeamMembers = createSelector(selectJobsData, (state: JobsState) => state.teamMembers);
export const selectJobUsers = (jobId: string) =>
  createSelector(selectJobsData, (state: JobsState) => {
    return state.jobs[jobId].userIds || [];
  });
export const selectJobTasks = (jobId: string) =>
  createSelector(selectJobsData, (state: JobsState) => {
    return state.jobs[jobId].tasks || null;
  });
export const selectIsLoadingTasks = createSelector(selectJobsData, (state: JobsState) => state.isLoadingTasks);
export const selectAllJobsLoaded = createSelector(selectJobsData, (state: JobsState) => state.allJobsLoaded);
export const selectIsAddingTasks = createSelector(selectJobsData, (state: JobsState) => state.isAddingTasks);
export const selectIsUpdatingJob = createSelector(selectJobsData, (state: JobsState) => state.isUpdatingJob);
export const selectJobMarkers = (jobId: string) =>
  createSelector(selectJobsData, (state: JobsState) => {
    return state.jobsMakers[jobId]?.features;
  });
