import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AtlasLocalStorageService} from '@app/atlas/services/atlas-local-storage.service';
import {Map, TileLayer} from 'leaflet';
import {EVENTS} from '../../../core/services/unleash-analytics.service';
import {MAP_VIEW} from '@app/atlas/model/map-view.mode';

declare let L; // leaflet global
@Component({
  selector: 'app-toggle-base-layer',
  templateUrl: './toggle-base-layer.component.html',
  styleUrls: ['./toggle-base-layer.component.scss']
})
export class ToggleBaseLayerComponent implements OnInit {
  public events = EVENTS;
  @Input()
  public map: Map;
  @Input()
  public worldMap: TileLayer;
  @Input()
  public streetMap: TileLayer;
  @Input()
  public weatherMap: TileLayer = null;
  @Input()
  public isWeatherMapEnabledForUser: boolean = false;
  @Input()
  public isWeatherMapDisplayed: boolean = true;
  @Input()
  public totalAssetsCount: number = 0;
  @Input()
  public isLayersControlSideBarOpen: boolean = false;
  @Input()
  public isTaskControl: boolean = false;
  @Input()
  public isMiniMapControl: boolean = false;
  @Input()
  public mapView: MAP_VIEW = MAP_VIEW.SATELLITE;
  @Input()
  public isClusterButtonDisabled: boolean = true;
  @Input()
  public hasInfoPopupControl: boolean = false;
  @Input()
  public isPopupEnabled: boolean = true;
  @Input()
  public isToggleClusterEnabled: boolean = false;

  @Output()
  public toggleWeather: EventEmitter<void> = new EventEmitter();
  @Output()
  public toggleMap: EventEmitter<void> = new EventEmitter();
  @Output()
  public toggleLayerControlSideBar: EventEmitter<void> = new EventEmitter();
  @Output()
  public toggleClusters: EventEmitter<void> = new EventEmitter();
  @Output()
  public togglePopups: EventEmitter<void> = new EventEmitter();

  public initializedWithWeatherMap: boolean = false;
  public mapViewMapper = {
    [MAP_VIEW.HYBRID]: {translation: 'map', analyticsType: 'Show Map'},
    [MAP_VIEW.ROADMAP]: {translation: 'terrain', analyticsType: 'Show Terrain'},
    [MAP_VIEW.SATELLITE]: {translation: 'hybrid', analyticsType: 'Show Hybrid'}
  };
  public MAP_VIEW = MAP_VIEW;

  constructor(private atlasLocalStorageService: AtlasLocalStorageService) {}

  public async ngOnInit() {
    await this.weatherMap;
    if (this.isWeatherMapEnabledForUser) {
      this.isWeatherMapDisplayed = true;
      this.initializedWithWeatherMap = true;
    }
    if (this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') === 'false') {
      this.isWeatherMapDisplayed = false;
    }
  }

  /**
   * Determines if clustering should be disabled based on the zoom level and the number of visible markers.
   * zoom >= DISABLE_CLUSTERING_ZOOM_LEVEL`, clustering is disabled.
   * zoom <= ENABLE_CLUSTERING_ZOOM_LEVEL, clustering is enabled.
   * ENABLE_CLUSTERING_ZOOM_LEVEL < zoom < DISABLE_CLUSTERING_ZOOM_LEVEL,
   * the function calculates the number of visible markers within the map's viewport and returns true if
   * the count is greater than or equal to `DISABLE_CLUSTERING_MARKERS_COUNT`.
   * @param {number} zoom - The current zoom level of the map.
   * @returns {boolean} - True if clustering should be disabled, false otherwise.
   */
  emitToggleMap() {
    this.toggleMap.emit();
  }

  emitToggleWeather() {
    this.toggleWeather.emit();
  }

  emitToggleLayerControlSideBar() {
    this.toggleLayerControlSideBar.emit();
  }

  emitToggleClusters() {
    this.toggleClusters.emit();
  }

  emitTogglePopups() {
    this.togglePopups.emit();
  }
}
