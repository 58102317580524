<ng-container *ngIf="headerStatus$ | async as headerStatus">
  <ng-container [ngSwitch]="headerStatus">
    <ng-container *ngSwitchCase="atlasHeaderStatus.DEFAULT">
      <ng-container *ngTemplateOutlet="defaultHeaderTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="atlasHeaderStatus.MISSION_PLANNER">
      <ng-container *ngTemplateOutlet="planMissionsHeaderTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="atlasHeaderStatus.ACTION_BAR">
      <ng-container *ngTemplateOutlet="actionBarHeaderTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="atlasHeaderStatus.COMPARE_LAYERS">
      <ng-container
        [ngTemplateOutlet]="genericHeaderTemplate"
        [ngTemplateOutletContext]="{
          mainTitle: 'atlas.actionBar.compareLayers' | translate,
          subtitle: 'atlas.actionBar.otherVisibleLayers' | translate,
          headerStatus: headerStatus
        }"
      >
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="atlasHeaderStatus.SELECT_MARKERS">
      <ng-container
        [ngTemplateOutlet]="genericHeaderTemplate"
        [ngTemplateOutletContext]="{
          mainTitle: 'atlas.control.selectMarkers' | translate,
          subtitle: (currentLayer$ | async).name,
          headerStatus: headerStatus
        }"
      >
      </ng-container>
      <nav class="global-action-bar">
        <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
          <button
            [disabled]="(selectedMarkers$ | async).length === 0"
            (click)="moveSelectedMarkers()"
            mat-button
            class="global-action-bar__button"
          >
            <mat-icon class="global-action-bar__icon-svg" svgIcon="move-selected-markers"></mat-icon>
            <span>{{ 'atlas.selectLayers.moveSelected' | translate }}</span>
          </button>
          <button
            [disabled]="(selectedMarkers$ | async).length === 0"
            (click)="copySelectedMarkers()"
            mat-button
            class="global-action-bar__button"
          >
            <mat-icon class="global-action-bar__icon-svg" svgIcon="copy-selected-markers"></mat-icon>
            <span>{{ 'atlas.selectLayers.copySelected' | translate }}</span>
          </button>
          <button
            [disabled]="(selectedMarkers$ | async).length === 0 && (hasDrawnItems | async) === false"
            (click)="clearSelections()"
            mat-button
            class="global-action-bar__button"
          >
            <mat-icon class="global-action-bar__icon-svg" svgIcon="clear-selection"></mat-icon>
            <span>{{ 'atlas.selectLayers.clearSelection' | translate }}</span>
          </button>
          <section *ngIf="isJobsEnabled$ | async" class="create-job">
            <button
              *uaHasPermission="aclPermissions.JobsApiCreateJob"
              [disabled]="(hasToEnableCreateJob$ | async) === false"
              [currentLayer]="currentLayer$ | async"
              [ulCreateJob]="selectedMarkers$ | async"
              [map]="map"
              mat-button
              class="global-action-bar__button create-job__button"
            >
              <mat-icon class="global-action-bar__icon-svg" svgIcon="jobs"></mat-icon>
              <span>{{ 'atlas.selectLayers.createJob' | translate }}</span>
            </button>
            <mat-icon
              *ngIf="createJobTooltip | async as tooltip"
              class="create-job__info"
              matPrefix
              [matTooltip]="'atlas.selectLayers.' + tooltip | translate"
              >info</mat-icon
            >
          </section>
        </section>
      </nav>
    </ng-container>
    <ng-container *ngSwitchCase="atlasHeaderStatus.EDIT_JOB_SHAPE">
      <ng-container
        [ngTemplateOutlet]="genericHeaderTemplate"
        [ngTemplateOutletContext]="{
          mainTitle: 'atlas.actionBar.editJobSelection.title' | translate,
          subtitle: editJobLayerTitle$ | async,
          headerStatus: headerStatus
        }"
      >
      </ng-container>
      <nav class="global-action-bar">
        <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
          <button
            [disabled]="(hasAddedEditChanges$ | async) === false || (isSavingJob | async)"
            (click)="discardJobsEdit()"
            mat-button
            class="global-action-bar__button"
          >
            <mat-icon class="global-action-bar__icon-svg">delete_outline</mat-icon>
            {{ 'atlas.actionBar.editJobSelection.discard' | translate }}
          </button>
          <button
            [disabled]="(hasAddedEditChanges$ | async) === false || (isSavingJob | async)"
            (click)="saveEditedJob()"
            mat-button
            class="global-action-bar__button"
          >
            <mat-icon class="global-action-bar__icon-svg">save</mat-icon>
            {{ 'atlas.actionBar.editJobSelection.saveChanges' | translate }}
            <unleash-spinner *ngIf="isSavingJob | async" diameter="20" borderWidth="2.1" color="#fff"></unleash-spinner>
          </button>
        </section>
      </nav>
    </ng-container>
  </ng-container>

  <ng-template #genericHeaderTemplate let-mainTitle="mainTitle" let-subtitle="subtitle" let-headerStatus="headerStatus">
    <nav class="global-process-bar">
      <div class="global-process-bar__container">
        <button
          [disabled]="headerStatus === atlasHeaderStatus.EDIT_JOB_SHAPE && (isSavingJob | async)"
          class="global-process-bar__button"
          mat-icon-button
          (click)="closeGenericHeader(headerStatus)"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="global-process-bar__info">
          <h1 class="global-process-bar__title">{{ mainTitle }}</h1>
          <div class="global-process-bar__title--subtitle">
            {{ subtitle }}
            <ng-container *ngIf="headerStatus === atlasHeaderStatus.COMPARE_LAYERS">
              <mat-icon class="compare-layers__custom-icon" svgIcon="movable-divider"></mat-icon>
              <ng-container *ngFor="let layer of atlasService.selectedLayers">
                <span class="compare-layers__name">{{ layer.name }}</span>
                <mat-icon class="compare-layers__custom-icon circle" svgIcon="circle"></mat-icon>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </nav>
  </ng-template>

  <ng-template #defaultHeaderTemplate>
    <nav class="global-action-bar">
      <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
        <button
          *uaHasPermission="aclPermissions.AtlasApiCreate"
          mat-button
          class="global-action-bar__button"
          data-test-id="layers-control-upload-button"
          (click)="openUploadDialog()"
        >
          <mat-icon class="global-action-bar__icon">upload</mat-icon>
          <span> {{ 'common.upload' | translate }} </span>
        </button>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          mat-button
          class="global-action-bar__button"
          (click)="toggleOpenAnnotations()"
        >
          <mat-icon class="global-action-bar__icon-svg" svgIcon="annotation-severity-white"></mat-icon>
          <span> {{ 'atlas.annotation.annotate' | translate }} </span>
        </button>
        <button
          mat-button
          class="global-action-bar__button"
          data-test-id="plan-missions-button"
          (click)="openPlanMissions()"
        >
          <mat-icon class="global-action-bar__icon-svg" svgIcon="leading-white"></mat-icon>
          <span> {{ 'atlas.mission.planMissions' | translate }} </span>
        </button>
      </section>
    </nav>
  </ng-template>

  <ng-template #planMissionsHeaderTemplate>
    <unleash-missions-header
      [aclPermissions]="aclPermissions"
      [selectedMission]="currentMission$ | async"
      [selectedWaypointIndex]="selectedWaypointIndex | async"
      (closeMissionViewer)="closeMissionViewer()"
      (missionCopiedToClipboard)="missionCopiedToClipboard()"
      (updatedMission)="missionViewerEnabled($event)"
    ></unleash-missions-header>
  </ng-template>

  <ng-template #compareLayersHeaderTemplate>
    <div class="global-process-bar">
      <div class="global-process-bar__container">
        <button class="global-process-bar__button" mat-icon-button (click)="closeCompareLayers()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="global-process-bar__info">
          <h1 class="global-process-bar__title">{{ 'atlas.actionBar.compareLayers' | translate }}</h1>
          <div class="global-process-bar__title--subtitle">
            {{ 'atlas.actionBar.otherVisibleLayers' | translate }}
            <mat-icon class="compare-layers__custom-icon" svgIcon="movable-divider"></mat-icon>
            <ng-container *ngFor="let layer of atlasService.selectedLayers">
              <span class="compare-layers__name">{{ layer.name }}</span>
              <mat-icon class="compare-layers__custom-icon circle" svgIcon="circle"></mat-icon>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #actionBarHeaderTemplate>
    <div *ngIf="totalSelectedLayers$ | async as totalSelectedLayers" class="action-bar mat-elevation-z3">
      <div class="action-bar__left">
        <mat-icon class="action-bar__close-icon" (click)="closeHeader()">close</mat-icon>
        {{ totalSelectedLayers }} {{ 'atlas.layerControl.selected' | translate }}
        <span class="action-bar__select-all" (click)="selectAllLayers()">{{
          'atlas.layerControl.selectAll' | translate
        }}</span>
      </div>
      <div class="action-bar__right">
        <button *uaHasPermission="aclPermissions.AtlasApiUpdate" mat-button color="primary" class="action-bar__item">
          <mat-icon class="action-bar__icon" svgIcon="add-group"></mat-icon>
          <span class="action-bar__name" (click)="moveSelectedAssets()">
            {{ 'atlas.control.addToGroup' | translate }}
          </span>
        </button>
        <div
          *ngIf="totalSelectedLayers > 0"
          [matTooltip]="'atlas.actionBar.compareLayersTooltip' | translate"
          [matTooltipDisabled]="totalSelectedLayers === 3"
          matTooltipPosition="below"
        >
          <button
            mat-button
            color="primary"
            class="action-bar__item"
            [disabled]="totalSelectedLayers > 3"
            (click)="compareLayers()"
          >
            <mat-icon class="action-bar__icon" [class.disabled]="totalSelectedLayers > 3" svgIcon="compare"></mat-icon>
            <span class="action-bar__name"> {{ 'atlas.control.compareLayers' | translate }} </span>
          </button>
        </div>
        <button mat-button color="primary" class="action-bar__item" (click)="toggleDisplaySelectedLayers(false)">
          <mat-icon class="action-bar__icon">visibility_off</mat-icon>
          <span class="action-bar__name"> {{ 'atlas.control.hide' | translate }} </span>
        </button>
        <button mat-button color="primary" class="action-bar__item" (click)="toggleDisplaySelectedLayers(true)">
          <mat-icon class="action-bar__icon">visibility</mat-icon>
          <span class="action-bar__name"> {{ 'atlas.control.show' | translate }} </span>
        </button>
        <button
          [disabled]="!(hasAllLayersLoaded$ | async)"
          mat-button
          color="primary"
          class="action-bar__item"
          (click)="showOnlySelectedLayers()"
        >
          <mat-icon class="action-bar__icon" svgIcon="visible_layer"></mat-icon>
          <span class="action-bar__name"> {{ 'atlas.control.showOnlySelectedLayers' | translate }}</span>
        </button>
        <button mat-icon-button class="action-bar__menu" [matMenuTriggerFor]="menu">
          <mat-icon class="action-bar__menu-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="atlas-menu">
          <button class="action-bar__menu-item" mat-menu-item (click)="changeLayerStackingOrder(true)">
            <mat-icon>flip_to_front</mat-icon>
            <span>{{ 'atlas.control.front' | translate }}</span>
          </button>
          <button class="action-bar__menu-item" mat-menu-item (click)="changeLayerStackingOrder(false)">
            <mat-icon>flip_to_back</mat-icon>
            <span>{{ 'atlas.control.back' | translate }}</span>
          </button>
          <button
            *uaHasPermission="aclPermissions.AtlasApiGeojson2shp"
            class="action-bar__menu-item"
            mat-menu-item
            [disabled]="
              totalSelectedLayers > 1 ||
              (totalSelectedLayers === 1 && atlasService.selectedLayers[0].type !== assetType.GEOJSON)
            "
            ulExportShape
          >
            <mat-icon [class.action-bar__disabled]="totalSelectedLayers > 1" svgIcon="export-layer"></mat-icon>
            <span>{{ 'atlas.control.exportToShapefile' | translate }}</span>
          </button>
          <ng-container *uaHasPermission="aclPermissions.AtlasApiDelete">
            <mat-divider class="action-bar__divider"></mat-divider>
            <button class="action-bar__menu-item delete" mat-menu-item (click)="removeSelectedAssets()">
              <mat-icon>delete</mat-icon>
              <span>{{ 'atlas.control.delete' | translate }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </ng-template>

  <mat-drawer-container class="dashboard-container" #drawerContainer autosize>
    <mat-drawer class="dashboard-container__drawer" #drawer mode="side">
      <div class="dashboard-container__content" *ngIf="!(atlasService.isLoading$ | async)">
        <div class="dashboard-container__header">
          {{ dashboardService.lgaName$ | async }}
          <mat-icon class="dashboard-container__close" (click)="drawer.close()">close</mat-icon>
        </div>
        <app-data-dashboard-component
          *ngIf="(atlasService.hasDashboardLayer$ | async) && (hasDashboardId$ | async)"
          isAtlasDashboard="true"
        ></app-data-dashboard-component>
      </div>
    </mat-drawer>
  </mat-drawer-container>
  <mat-progress-bar
    *ngIf="!(atlasService.hasAllLayersLoaded$ | async)"
    class="loading"
    mode="indeterminate"
  ></mat-progress-bar>
  <app-nav-back
    *ngIf="hasGoBack$ | async"
    [hasToUseStoredNavigation]="true"
    text="{{ 'atlas.pointmap.back' | translate }}"
  ></app-nav-back>
  <mat-sidenav-container>
    <mat-sidenav
      class="sidenav__layers-control"
      [class.sidenav__mission]="isMissionPlannerActive$ | async"
      #sidenav
      mode="side"
      (keydown.esc)="closeLayersControlSideBar()"
      [opened]="isLayersControlSideBarOpen$ | async"
      position="start"
      autosize="false"
      (closedStart)="onSidebarClose()"
      (openedChange)="openedSidebarChange($event)"
    >
      <div class="main-content" *ngIf="searchResult$ | async as searchResult">
        <unleash-properties-search-results
          [map]="map"
          [asset]="searchResult"
          [searchFilterQuery]="searchFilterQuery$ | async"
          (goBack)="setSearchResultsActive(null); closeSearchSidebar($event)"
          (selectSearchedLayer)="selectSearchedLayer($event)"
        ></unleash-properties-search-results>
      </div>
      <section class="main-content" [class.hide]="!!(searchResult$ | async)">
        <ng-container *ngIf="isMissionPlannerActive$ | async; else layersTemplate">
          <app-mission-control
            [isMissionDrawerClosed]="isMissionDrawerClosed"
            [selectedWaypointIndex]="selectedWaypointIndex | async"
            [selectedMission]="currentMission$ | async"
            (selectedWaypoint)="assignSelectedWaypoint($event)"
            (missionViewerEnabled)="missionViewerEnabled($event)"
            (newWaypoint)="newWaypoint($event)"
            (missionCopiedToClipboard)="missionCopiedToClipboard()"
          ></app-mission-control>
        </ng-container>
        <ng-template #layersTemplate>
          <app-layers-control
            (showDashboard)="showDashboard($event)"
            (toggleStream)="atlasVideoComponent?.toggleStream({id: $event})"
            (exportShape)="exportShp($event)"
            (exportToGeojson)="exportToGeojson($event)"
            (exportToGeotiff)="exportToGeotiff($event)"
            (compareLayer)="compareLayers($event)"
            (refreshView)="refreshView()"
            (showOnlyThisLayer)="showOnlyThisLayer($event)"
            (hideSideBar)="toggleLayerControlSideBar()"
            (openSearchResults)="setSearchResultsActive($event); displayLayer($event)"
            [map]="map"
            [isModellingEnabled]="isModellingEnabled$ | async"
            [totalSelectedLayers]="totalSelectedLayers$ | async"
            [hasAllLayersLoaded]="atlasService.hasAllLayersLoaded$ | async"
            [hasToBlockMenu]="
              (headerStatus$ | async) === atlasHeaderStatus.COMPARE_LAYERS ||
              (headerStatus$ | async) === atlasHeaderStatus.SELECT_MARKERS ||
              (headerStatus$ | async) === atlasHeaderStatus.EDIT_JOB_SHAPE
            "
          >
          </app-layers-control>
        </ng-template>
      </section>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav__content-map">
      <!-- <ks-modal-gallery
      *ngIf="currentSession$ | async as currentSession"
      [session]="currentSession"
      [slideConfig]="slideConfig"
      [currentImageConfig]="currentImageConfig"
      [previewConfig]="previewConfig"
      [imageIndexToStart]="imageIndexToStart$ | async"
    ></ks-modal-gallery> -->
      <unleash-context-menu #contextMenu [matMenu]="matMenu">
        <mat-menu #matMenu>
          <button mat-menu-item (click)="copyLatLngContextMenu()">
            <mat-icon>gps_fixed</mat-icon>
            <span>{{ 'atlas.map.copyLocation' | translate }}</span>
          </button>
        </mat-menu>
      </unleash-context-menu>
      <div
        [ngClass]="{'display-compare-layer': isDisplayingCompareLayer$ | async}"
        leaflet
        [leafletOptions]="newMapOptions"
        (leafletMapReady)="onNewMapReady($event)"
      ></div>
      <div
        data-test-id="map"
        [ngClass]="{'hide-normal-layer-by-compare-layer': isDisplayingCompareLayer$ | async}"
        class="map-container"
        (contextmenu)="$event.preventDefault()"
        leaflet
        [leafletOptions]="options"
        [leafletPanOptions]="panOptions"
        [leafletZoomOptions]="zoomOptions"
        [leafletZoomPanOptions]="zoomPanOptions"
        [leafletLayers]="atlasService.deviceFlightLayers$ | async"
        (leafletMapReady)="onMapReady($event)"
      >
        <ng-container *ngFor="let gpsDevice of gpsDevices$ | async; trackBy: trackById">
          <atlas-device
            [map]="map"
            [device]="gpsDevice"
            [timeControlsVisible]="false"
            [isStreaming$]="isDeviceStreaming$(gpsDevice)"
            (emitIotData)="atlasVideoComponent?.iotDataChange($event, gpsDevice)"
            (layersCreated)="addLayers($event)"
            (toggleStream)="atlasVideoComponent?.toggleStream({id: $event})"
          ></atlas-device>
        </ng-container>
        <ng-container *ngFor="let flightLog of assetsFilterService.flightLogs$ | async">
          <atlas-flight-log [asset]="flightLog" [map]="map"></atlas-flight-log>
        </ng-container>

        <div class="leaflet-control-container">
          <div class="leaflet-top leaflet-left">
            <div class="unleash-tools">
              <app-annotation-control
                *ngIf="!(isMissionPlannerActive$ | async)"
                [hasToOpenAnnotationsOptions]="hasToOpenAnnotationsOptions$ | async"
                [isComparingLayers]="headerStatus === atlasHeaderStatus.COMPARE_LAYERS"
                [isSelectingMarkers]="headerStatus === atlasHeaderStatus.SELECT_MARKERS"
                (assetsUploaded)="assetUploaded()"
                [map]="map"
              ></app-annotation-control>
            </div>
          </div>

          <div *ngIf="addonModellingEnabled$ | async" class="leaflet-bottom leaflet-left">
            <div class="layers-control">
              <app-toggle-base-layer
                [map]="map"
                [streetMap]="roadView"
                [worldMap]="satelliteView"
                [weatherMap]="weatherMap"
                [isWeatherMapEnabledForUser]="isWeatherMapEnabledForUser"
                [isWeatherMapDisplayed]="isWeatherMapDisplayed"
                [mapView]="mapView"
                [totalAssetsCount]="atlasService.totalAssetsCount"
                [isLayersControlSideBarOpen]="isLayersControlSideBarOpen$ | async"
                [isClusterButtonDisabled]="isClusterButtonDisabled$ | async"
                [isToggleClusterEnabled]="true"
                (toggleWeather)="toggleWeather()"
                (toggleMap)="toggleMap()"
                (toggleLayerControlSideBar)="toggleLayerControlSideBar()"
                (toggleClusters)="toggleClusters()"
              ></app-toggle-base-layer>
              <app-zoom-buttons [map]="map"></app-zoom-buttons>
              <button
                class="layers-control__button"
                mat-flat-button
                matTooltip="{{ 'atlas.map.defaultView' | translate }}"
                matTooltipPosition="right"
                [matMenuTriggerFor]="favouriteAtlasPosition"
                data-test-id="default-view"
              >
                <mat-icon svgIcon="home_map_marker"></mat-icon>
              </button>
              <mat-menu class="layers-control__favourite-position" #favouriteAtlasPosition="matMenu">
                <button
                  [disabled]="
                    !(atlasService.hasAllLayersLoaded$ | async) || headerStatus === atlasHeaderStatus.COMPARE_LAYERS
                  "
                  data-test-id="set-view-as-default"
                  mat-menu-item
                  (click)="saveCurrentViewAsDefault()"
                >
                  <mat-icon svgIcon="set_default_view"></mat-icon>
                  {{ 'atlas.map.setCurrentView' | translate }}
                </button>
                <button data-test-id="go-to-default-view" mat-menu-item (click)="goToDefaultView()">
                  <mat-icon svgIcon="go_to_default"></mat-icon>
                  {{ 'atlas.map.goToDefaultView' | translate }}
                </button>
              </mat-menu>
            </div>
          </div>

          <atlas-video
            #atlasVideo
            [gpsDevices$]="gpsDevices$"
            (emitShowLayer)="showLayer($event)"
            (deviceUpdated)="deviceUpdated()"
          ></atlas-video>
          <unleash-select-markers
            *ngIf="hasToShowSelectOptions$ | async"
            [totalSelectedMarkers]="(selectedMarkers$ | async).length"
            [activeSelectedOption]="selectMarkersCurrentOption | async"
            [isEditJobMode]="(headerStatus$ | async) === atlasHeaderStatus.EDIT_JOB_SHAPE"
            (selectByBox)="selectByBox()"
            (selectByPolygon)="selectByPolygon()"
            (selectByClick)="selectByClick()"
          ></unleash-select-markers>
        </div>
      </div>
      <ua-option-sidemenu
        [map]="map"
        [selectedWaypoint]="selectedWaypoint | async"
        [isLayersControlSideBarOpen]="_isLayersControlSideBarOpen | async"
        (missionDrawerClosed)="setIsMissionDrawerClosed()"
        (selectedPointIndex)="setSelectedWaypointIndex($event)"
        *ngIf="isMissionPlannerActive$ | async"
      ></ua-option-sidemenu>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ng-container *ngIf="(addonModellingEnabled$ | async) === false">
    <div class="teaser-message-wrapper">
      <app-teaser-message></app-teaser-message>
    </div>
  </ng-container>
</ng-container>
