import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {Team} from '@app/profile/models/team.model';
import {UserInvitation} from '@app/profile/models/user-roles.model';
import {UserTileScreen} from '@app/profile/models/user-tile-screen.model';
import {Roles, UserAction, UserTable, UserTableActions, UserTeam} from '@app/profile/models/users-table.model';
import {TeamsAccountManagementService} from '@app/profile/services/teams-account-management.service';
import {UserTeamsAccountManagementService} from '@app/profile/services/user-teams-account-management.service';
import {UsersAccountManagementService} from '@app/profile/services/users-account-management.service';
import {Observable, shareReplay} from 'rxjs';

@Component({
  selector: 'unleash-users-tile',
  templateUrl: './users-tile.component.html',
  styleUrls: ['./users-tile.component.scss']
})
export class UsersTileComponent {
  public form: FormGroup = this.fb.group({
    search: ''
  });
  public users$: Observable<UserTable[]> = this.userAccountManagementService.usersTable$.pipe(shareReplay(1));
  public selectedUser$: Observable<UserTeam> = this.userAccountManagementService.selectedUser$.pipe(shareReplay(1));
  public menuItems$: Observable<UserTableActions[]> = this.userAccountManagementService.userMenuActions$.pipe(
    shareReplay(1)
  );
  public userScreen$: Observable<UserTileScreen> = this.userAccountManagementService.userScreen$.pipe(shareReplay(1));
  public roles$: Observable<Roles> = this.userAccountManagementService.roles$.pipe(shareReplay(1));
  public teams$: Observable<Team[]> = this.teamsAccountManagementService.teams$;
  public hasMoreThanOneAdmin$: Observable<boolean> = this.userAccountManagementService.hasMoreThanOneAdmin$;
  public currentTeamId$: Observable<string> = this.userStoreFacadeService.currentTeamId$;
  public userTileScreen = UserTileScreen;

  constructor(
    private fb: FormBuilder,
    private userAccountManagementService: UsersAccountManagementService,
    private teamsAccountManagementService: TeamsAccountManagementService,
    private userStoreFacadeService: UserStoreFacadeService,
    private userTeamsAccountManagementService: UserTeamsAccountManagementService
  ) {}

  public performSearch(search: string): void {
    this.form.controls.search.setValue(search);
    this.userAccountManagementService.filterUsers(search);
  }

  public invite(userInvitations: UserInvitation[]): void {
    this.userAccountManagementService.addUsers(userInvitations);
    this.userAccountManagementService.displayUsersView();
  }

  public cancel(): void {
    this.userAccountManagementService.displayUsersView();
    this.userAccountManagementService.removeSelectedUserId();
    this.userTeamsAccountManagementService.displayUserTeamsView();
    this.userTeamsAccountManagementService.removeSelectedUserTeam();
  }

  public user(user: UserTable): void {
    this.userAccountManagementService.selectUser(user);
  }

  public action(action: UserAction): void {
    this.userAccountManagementService.executeUserAction(action);
  }

  public openInviteUsersDialog(): void {
    this.userTeamsAccountManagementService.openInviteUsersDialog();
  }
}
