import {createAction, props} from '@ngrx/store';
import {JOB_STATUS, Job, Task} from '../models/jobs.models';

export enum JOBS_ACTIONS {
  STORE_JOBS = '[Jobs] store jobs',
  STORE_JOBS_SUCCESS = '[Jobs] store jobs success',
  CREATE_NEW_JOB = '[Jobs] create new job',
  CREATE_NEW_JOB_SUCCESS = '[Jobs] create new job success',
  STORE_SINGLE_JOB = '[Jobs] store single job',
  STORE_SINGLE_JOB_SUCCESS = '[Jobs] store single job success',
  UPDATE_IS_LOADING_SINGLE_JOB = '[Jobs] update is loading single job',
  UPDATE_IS_LOADING_MARKERS = '[Jobs] update is loading markers',
  STORE_TEAM_MEMBERS_SUCCESS = '[Jobs] store team members success',
  OPEN_ADD_USERS = '[Jobs] open add users',
  UPDATE_JOB_SUCCESS = '[Jobs] update job success',
  STORE_JOB_TASKS = '[Jobs] store job tasks',
  STORE_JOB_TASKS_SUCCESS = '[Jobs] store job tasks success',
  UPDATE_IS_LOADING_TASKS = '[Jobs] update is loading tasks',
  UPDATE_IS_LOADING_JOBS = '[Jobs] update is loading jobs',
  ADD_TASKS = '[Jobs] add tasks',
  ADD_MISSION_TASKS = '[Jobs] add mission tasks',
  ADD_TASK_SUCCESS = '[Jobs] add task success',
  UPDATE_IS_ADDING_TASKS = '[Jobs] update is adding tasks',
  UPDATE_JOB = '[Jobs] update job',
  UPDATE_IS_UPDATING_JOB = '[Jobs] update is updating job',
  OPEN_DELETE_JOB = '[Jobs] open delete job',
  DELETE_JOB_SUCCESS = '[Jobs] delete job success',
  OPEN_RENAME_JOB = '[Jobs] open rename job',
  OPEN_DELETE_TASK = '[Jobs] open delete task',
  DELETE_TASK_SUCCESS = '[Jobs] delete task success',
  RESET_TASK_NEXT_TOKEN = '[Jobs] reset task next token',
  UPDATE_TASK = '[Jobs] update task',
  UPDATE_TASK_SUCCESS = '[Jobs] update task success',
  OPEN_MORE_DETAILS = '[Jobs] open more details',
  CLEAR_TASK_LIST = '[Jobs] clear task list',
  CREATE_FLIGHTPATH_TASKS = '[Jobs] create flightpath tasks',
  LOAD_JOB_MARKERS = '[Jobs] load job markers',
  LOAD_JOB_MARKERS_SUCCESS = '[Jobs] load job markers success',
  STORE_TEAM_MEMBERS = '[Jobs] store team members'
}

export const storeJobs = createAction(JOBS_ACTIONS.STORE_JOBS);
export const storeJobsSuccess = createAction(JOBS_ACTIONS.STORE_JOBS_SUCCESS, props<{items: Job[]; nextToken}>());
export const createNewJob = createAction(JOBS_ACTIONS.CREATE_NEW_JOB);
export const createNewJobSuccess = createAction(JOBS_ACTIONS.CREATE_NEW_JOB_SUCCESS, props<{job: Job}>());
export const storeSingleJob = createAction(JOBS_ACTIONS.STORE_SINGLE_JOB, props<{jobId: string}>());
export const storeSingleJobSuccess = createAction(JOBS_ACTIONS.STORE_SINGLE_JOB_SUCCESS, props<{job: Job}>());
export const updateIsLoadingSingleJob = createAction(
  JOBS_ACTIONS.UPDATE_IS_LOADING_SINGLE_JOB,
  props<{isLoadingSingleJob: boolean}>()
);
export const storeTeamMembersSuccess = createAction(
  JOBS_ACTIONS.STORE_TEAM_MEMBERS_SUCCESS,
  props<{teamMembers: any[]}>()
);
export const openAddUsers = createAction(JOBS_ACTIONS.OPEN_ADD_USERS, props<{jobId: string}>());
export const updateJobSuccess = createAction(
  JOBS_ACTIONS.UPDATE_JOB_SUCCESS,
  props<{jobId: string; params: Partial<Job>}>()
);
export const storeJobTasks = createAction(JOBS_ACTIONS.STORE_JOB_TASKS, props<{jobId: string}>());
export const storeJobTasksSuccess = createAction(
  JOBS_ACTIONS.STORE_JOB_TASKS_SUCCESS,
  props<{tasks: any[]; jobId: string; nextToken: string}>()
);
export const updateIsLoadingTasks = createAction(
  JOBS_ACTIONS.UPDATE_IS_LOADING_TASKS,
  props<{isLoadingTasks: boolean}>()
);
export const updateIsLoadingJobs = createAction(JOBS_ACTIONS.UPDATE_IS_LOADING_JOBS, props<{isLoadingJobs: boolean}>());
export const addTasks = createAction(
  JOBS_ACTIONS.ADD_TASKS,
  props<{jobId: string; tasks: any[]; selectedAssetId: string}>()
);
export const addMissionTasks = createAction(JOBS_ACTIONS.ADD_MISSION_TASKS, props<{jobId: string; tasks: any[]}>());
export const addTasksSuccess = createAction(JOBS_ACTIONS.ADD_TASK_SUCCESS, props<{jobId: string; task: Task}>());
export const updateIsAddingTasks = createAction(JOBS_ACTIONS.UPDATE_IS_ADDING_TASKS, props<{isAddingTasks: boolean}>());
export const updateJob = createAction(JOBS_ACTIONS.UPDATE_JOB, props<{jobId: string; params: Partial<Job>}>());
export const updateIsUpdatingJob = createAction(JOBS_ACTIONS.UPDATE_IS_UPDATING_JOB, props<{isUpdatingJob: boolean}>());
export const openDeleteJob = createAction(
  JOBS_ACTIONS.OPEN_DELETE_JOB,
  props<{jobId: string; name: string; jobStatus: JOB_STATUS; assetId: string; hasToRedirect: boolean}>()
);
export const deleteJobSuccess = createAction(
  JOBS_ACTIONS.DELETE_JOB_SUCCESS,
  props<{jobId: string; jobStatus: JOB_STATUS}>()
);
export const openRenameJob = createAction(JOBS_ACTIONS.OPEN_RENAME_JOB, props<{jobId: string; name: string}>());
export const openDeleteTask = createAction(JOBS_ACTIONS.OPEN_DELETE_TASK, props<{taskId: string; name: string}>());
export const deleteTaskSuccess = createAction(
  JOBS_ACTIONS.DELETE_TASK_SUCCESS,
  props<{taskId: string; jobId: string}>()
);
export const resetTaskNextToken = createAction(JOBS_ACTIONS.RESET_TASK_NEXT_TOKEN);
export const updateTask = createAction(
  JOBS_ACTIONS.UPDATE_TASK,
  props<{taskId: string; jobId: string; params: Partial<Task>}>()
);
export const updateTaskSuccess = createAction(
  JOBS_ACTIONS.UPDATE_TASK_SUCCESS,
  props<{taskId: string; jobId: string; params: Partial<Task>}>()
);
export const openMoreDetails = createAction(JOBS_ACTIONS.OPEN_MORE_DETAILS, props<{task: Task; jobName: string}>());
export const clearTaskList = createAction(JOBS_ACTIONS.CLEAR_TASK_LIST, props<{jobId: string}>());
export const createFlightPathTasks = createAction(
  JOBS_ACTIONS.CREATE_FLIGHTPATH_TASKS,
  props<{payload: {jobId: string; flightPathTasks: any}}>()
);

export const loadJobMarkers = createAction(JOBS_ACTIONS.LOAD_JOB_MARKERS, props<{payload: {job: Job}}>());

export const loadJobMarkersSuccess = createAction(
  JOBS_ACTIONS.LOAD_JOB_MARKERS_SUCCESS,
  props<{payload: {jobId: string; jobMarkers: any}}>()
);
export const storeTeamMembers = createAction(JOBS_ACTIONS.STORE_TEAM_MEMBERS);
export const updateIsLoadingMarkers = createAction(
  JOBS_ACTIONS.UPDATE_IS_LOADING_MARKERS,
  props<{isLoadingMarkers: boolean}>()
);
