import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {OrganisationComponent} from './components/organisation/organisation.component';
import {UsersTileComponent} from './components/users/users-tile/users-tile.component';
import {TeamsTileComponent} from './components/teams/teams-tile/teams-tile.component';
import {StreamingComponent} from './components/streaming/streaming.component';
import {AuthorizationTileComponent} from './components/authorization-tile/authorization-tile.component';
import {DeveloperComponent} from './components/developer/developer.component';
import {AclCanActivateGuard} from '@app/core/guards/acl.guards';
import {AclPermissions} from '@app/core/models/api/acl.model';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'your-profile',
    pathMatch: 'full'
  },
  {
    path: 'your-profile',
    component: UserProfileComponent
  },
  {
    path: 'organization',
    component: OrganisationComponent
  },
  {
    path: 'users',
    component: UsersTileComponent,
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.OrganizationApiGetAdminOrganizationData
    }
  },
  {
    path: 'teams',
    component: TeamsTileComponent,
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.OrganizationApiGetAdminOrganizationData
    }
  },
  {
    path: 'streaming-devices',
    component: StreamingComponent
  },
  {
    path: 'data-privacy',
    component: AuthorizationTileComponent,
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.OrganizationApiUpdateCompany
    }
  },
  {
    path: 'developers',
    component: DeveloperComponent,
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.AddonApiCliAccess
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule {}
