import {Injectable} from '@angular/core';
import {atlasConfig} from '@app/atlas/atlas.config';
import {GeoJSONFeature} from '@app/atlas/model/mission-geojson.model';
import {divIcon, Map, point} from 'leaflet';
import {BehaviorSubject} from 'rxjs';
import Supercluster from 'supercluster';

@Injectable({
  providedIn: 'root'
})
export abstract class SharedSuperclusterService {
  protected supercluster;
  public currentDisplayedPoints: GeoJSONFeature[] = [];
  public currentDisplayedPointsObject = {};
  public isClusterEnabled = false;
  public isClusterButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public stickyClustersEnabled = false;
  private threshold = atlasConfig.DISABLE_CLUSTERING_MARKERS_COUNT;

  public abstract updateClusters(map: Map);
  public abstract addSingleMarker(point, latitude, longitude, map);
  public abstract displayedClusters;

  private readonly genericClusterId = 'cluster';

  constructor() {}

  protected loadSuperCluster(points: GeoJSONFeature[]): void {
    this.supercluster = new Supercluster({
      radius: atlasConfig.SUPERCLUSTER_RADIUS,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      map: properties => ({featureColor: [properties.featureColor]}),
      reduce: (accumulated, props) => {
        accumulated.featureColor = [...accumulated.featureColor, ...props.featureColor];
        accumulated.clusterColor = accumulated.featureColor.reduce((acc, item) => {
          acc[item] = (acc[item] || 0) + 1;
          return acc;
        }, {});
      }
    });
    this.supercluster.load(points);
  }

  public setClusterThreshold(threshold: number) {
    this.threshold = threshold;
  }

  public refreshCluster(points: GeoJSONFeature[], map: Map) {
    this.setCurrentDisplayedPoints(points);
    this.setIsClusterEnabled(true);
    this.loadSuperCluster(points);
    this.updateClusters(map);
  }

  protected setCurrentDisplayedPoints(currentDisplayedPoints: GeoJSONFeature[]) {
    this.currentDisplayedPoints = currentDisplayedPoints;

    this.currentDisplayedPointsObject = currentDisplayedPoints.reduce((acc, item: any) => {
      const assetId = item.asset?.id || this.genericClusterId;
      if (!acc[assetId]) {
        acc[assetId] = {};
      }
      acc[assetId][item.index] = item;
      return acc;
    }, {});
  }

  protected setIsClusterEnabled(isClusterEnabled: boolean) {
    this.isClusterEnabled = isClusterEnabled;
  }

  public listenClusterChanges(map: Map) {
    if (!this.isClusterEnabled) {
      this.enableCluster(map);
    }
    this.updateClusters(map);
    this.handleMapChange(map);
  }

  public enableCluster(map: Map) {
    if (this.isClusterEnabled) {
      return;
    }
    this.setIsClusterEnabled(true);
    this.updateClusters(map);
  }

  public handleMapChange(map) {
    const zoom = map.getZoom();
    console.info('CURRENT ZOOM', zoom);
    const hasToEnableCluster =
      (this.shouldEnableClusteringByMarkers() || !!this.shouldEnableClusteringByZoom(zoom)) &&
      zoom !== atlasConfig.MAX_ZOOM_LEVEL;
    this.isClusterButtonDisabled.next(hasToEnableCluster);
    if (this.stickyClustersEnabled) {
      console.debug('Sticky clusters enabled');
      return;
    }
    this.isClusterButtonDisabled.value ? this.enableCluster(map) : this.disableCluster(map);
  }

  private shouldEnableClusteringByZoom(zoom: number): boolean {
    if (zoom >= atlasConfig.DISABLE_CLUSTERING_ZOOM_LEVEL) {
      return false;
    } else if (zoom <= atlasConfig.ENABLE_CLUSTERING_ZOOM_LEVEL) {
      return true;
    }
    return false;
  }

  private shouldEnableClusteringByMarkers() {
    let visiblePoints = 0;
    for (const cluster of this.displayedClusters) {
      const pointsToAdd = cluster.properties.cluster ? cluster.properties.point_count : 1;
      visiblePoints += pointsToAdd;
      if (visiblePoints > this.threshold) {
        break;
      }
    }
    return visiblePoints >= this.threshold;
  }

  public disableCluster(map: Map) {
    if (!this.isClusterEnabled) {
      return;
    }
    this.setIsClusterEnabled(false);
    this.updateClusters(map);
  }

  public toggleCluster(map: Map) {
    if (this.isClusterEnabled) {
      this.disableCluster(map);
      this.stickyClustersEnabled = false;
      return;
    }
    this.enableCluster(map);
    this.stickyClustersEnabled = true;
  }

  public getPoint(assetId: string, index: number) {
    return this.currentDisplayedPointsObject?.[assetId]?.[index];
  }

  public getIconCreateFunction(childCount, groupColors) {
    return divIcon({
      html: this.getClusterHtml(groupColors, childCount),
      className: 'custom-cluster',
      iconSize: point(40, 40)
    });
  }

  public getClusterHtml(colorCounts: {[key: string]: number}, total: number) {
    let cumulativePercentage = 0;
    const gradientParts = [];

    for (const color in colorCounts) {
      const PERCENTAGE_FACTOR = 100;
      const percentage = (colorCounts[color] / total) * PERCENTAGE_FACTOR;
      gradientParts.push(`${color} ${cumulativePercentage}% ${cumulativePercentage + percentage}%`);
      cumulativePercentage += percentage;
    }
    const gradient = `conic-gradient(${gradientParts.join(', ')})`;
    const containerStyle = `background: ${gradient};`;

    return `<div class="custom-cluster__container" style="${containerStyle}"></div>
            <div class="custom-cluster__icon size-indefinite">${total}</div>`;
  }

  public updateUnclusteredMarkers(map) {
    const points = this.displayedClusters.flatMap(cluster =>
      cluster.properties.cluster ? this.supercluster.getLeaves(cluster.properties.cluster_id, Infinity) : [cluster]
    );
    points.forEach(point => {
      const [longitude, latitude] = point.geometry.coordinates;
      this.addSingleMarker(point, latitude, longitude, map);
    });
  }
}
