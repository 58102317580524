<form [formGroup]="form" (submit)="clickSignUpButton()" class="form">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.name' | translate }}</mat-label>
    <input matInput formControlName="name" autofocus="true" autocomplete="off" data-test-id="nameInput" />
    <mat-error *ngIf="(form.controls.name.invalid && form.controls.name.touched) || form.controls.name.dirty"
      >{{ 'common.name' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="!isOrganizationSignUp">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" autocomplete="off" data-test-id="emailInput" />
    <mat-error *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty"
      >{{ 'common.email' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.password' | translate }}</mat-label>
    <input
      matInput
      formControlName="password"
      placeholder="{{ 'auth.forgot.passwordPlaceholder' | translate }}"
      autocomplete="new-password"
      data-test-id="passwordInput"
      [type]="hasToHide ? 'password' : 'text'"
    />
    <mat-icon matSuffix (click)="hasToHide = !hasToHide">{{ hasToHide ? 'visibility_off' : 'visibility' }}</mat-icon>
    <mat-error
      *ngIf="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.dirty"
      >{{ 'auth.forgot.passwordPlaceholder' | translate }}</mat-error
    >
  </mat-form-field>

  <unleash-checkbox
    class="form__item"
    formControlName="terms"
    [onToggle]="cleanTermsAndConditionError"
    data-test-id="checkboxInput"
  >
    <div [innerHTML]="'auth.signUp.terms' | translate"></div>
    <mat-error
      *ngIf="
        (form.controls.terms.invalid && form.controls.terms.touched) ||
        (form.controls.terms.dirty && form.controls.terms.invalid)
      "
    >
      {{ 'auth.signUp.termsError' | translate }}
    </mat-error>
  </unleash-checkbox>

  <button
    class="form__item sign-up-button"
    mat-raised-button
    color="primary"
    [appLadda]="inProgress"
    [disabled]="inProgress"
    data-test-id="signUpButton"
  >
    {{ 'auth.signUp.signUp' | translate }}
  </button>

  <!--  <unleash-divider class="form__item">OR</unleash-divider>

  <div class="form__item social-buttons">
    <unleash-social-button
      [disabled]="true"
      class="social-buttons__google"
      imageSrc="/assets/icons/auth/logo-google.svg"
      textContent="SIGN UP WITH GOOGLE"
      (click)="clickGoogleButton()"
    ></unleash-social-button>
    <unleash-social-button
      [disabled]="true"
      class="social-buttons__facebook"
      imageSrc="/assets/icons/auth/logo-facebook.svg"
      (click)="clickFacebookButton()"
    ></unleash-social-button>
  </div> -->
</form>
