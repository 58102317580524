import {createReducer, on} from '@ngrx/store';
import * as jobsActions from './jobs.actions';
import {Job, JOB_STATUS, Task} from '../models/jobs.models';
import {TeamRole} from '@app/profile/models/team.model';

export const jobsFeatureKey = 'jobsStore';

export interface JobsState {
  filteredJobs: {
    inProgress: string[];
    draft: string[];
    complete: string[];
    failed: string[];
    published: string[];
  };
  jobs: {[key: string]: Job};
  jobsMakers: {[key: string]: any};
  teamMembers: {[key: string]: {id: string; name: string; role: TeamRole}};
  isLoadingJobs: boolean;
  isLoadingSingleJob: boolean;
  isLoadingTasks: boolean;
  isLoadingMarkers: boolean;
  nextToken: string;
  allJobsLoaded: boolean;
  isAddingTasks: boolean;
  isUpdatingJob: boolean;
  nextTokenTasks: string;
}
const initialState: JobsState = {
  filteredJobs: {
    inProgress: [],
    draft: [],
    complete: [],
    failed: [],
    published: []
  },
  jobs: {},
  jobsMakers: {},
  isLoadingJobs: false,
  isLoadingSingleJob: false,
  isLoadingTasks: false,
  isLoadingMarkers: false,
  nextToken: null,
  teamMembers: {},
  allJobsLoaded: false,
  isAddingTasks: false,
  isUpdatingJob: false,
  nextTokenTasks: null
};

export const jobsReducer = createReducer(
  initialState,
  on(
    jobsActions.storeJobsSuccess,
    (state: JobsState, {items, nextToken}: {items: Job[]; nextToken: string}): JobsState => {
      const inProgress = items.filter(job => job.status === JOB_STATUS.IN_PROGRESS).map(job => job.id);
      const draft = items.filter(job => job.status === JOB_STATUS.DRAFT).map(job => job.id);
      const complete = items.filter(job => job.status === JOB_STATUS.COMPLETED).map(job => job.id);
      const failed = items.filter(job => job.status === JOB_STATUS.FAILED).map(job => job.id);
      const published = items.filter(job => job.status === JOB_STATUS.PUBLISHED).map(job => job.id);
      const jobs = items.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});
      let isLoadingJobs = true;
      if (nextToken === undefined) {
        isLoadingJobs = false;
      }
      return {
        ...state,
        filteredJobs: {
          inProgress: [...new Set([...state.filteredJobs.inProgress, ...inProgress])],
          draft: [...new Set([...state.filteredJobs.draft, ...draft])],
          complete: [...new Set([...state.filteredJobs.complete, ...complete])],
          failed: [...new Set([...state.filteredJobs.failed, ...failed])],
          published: [...new Set([...state.filteredJobs.published, ...published])]
        },
        nextToken,
        isLoadingJobs,
        jobs: {...jobs, ...state.jobs},
        allJobsLoaded: true
      };
    }
  ),
  on(jobsActions.createNewJobSuccess, (state: JobsState, {job}: {job: Job}): JobsState => {
    return {
      ...state,
      filteredJobs: {...state.filteredJobs, draft: [...state.filteredJobs.draft, job.id]},
      jobs: {...state.jobs, [job.id]: job}
    };
  }),
  on(jobsActions.storeSingleJobSuccess, (state: JobsState, {job}: {job: Job}): JobsState => {
    const status = mapStauts(job.status);
    const updatedStatusJobs = [...new Set([...state.filteredJobs[status], job.id])];
    return {
      ...state,
      filteredJobs: {...state.filteredJobs, [status]: updatedStatusJobs},
      jobs: {...state.jobs, [job.id]: job},
      isLoadingSingleJob: false
    };
  }),
  on(
    jobsActions.updateIsLoadingSingleJob,
    (state: JobsState, {isLoadingSingleJob}: {isLoadingSingleJob: boolean}): JobsState => {
      return {
        ...state,
        isLoadingSingleJob
      };
    }
  ),
  on(jobsActions.storeTeamMembersSuccess, (state: JobsState, {teamMembers}: {teamMembers: any[]}): JobsState => {
    const team = teamMembers.reduce((acc, obj) => {
      acc[obj?.userId] = {id: obj?.userId, name: obj?.name, role: obj?.roles[0]};
      return acc;
    }, {});
    return {
      ...state,
      teamMembers: team
    };
  }),
  on(
    jobsActions.updateJobSuccess,
    (state: JobsState, {jobId, params}: {jobId: string; params: Partial<Job>}): JobsState => {
      const jobs = {...state.jobs};
      if (!jobs[jobId]) {
        return state;
      }
      let filteredJobs = {...state.filteredJobs};
      if (params?.status && state.jobs[jobId].status !== params?.status) {
        const updatedStatus = mapStauts(params.status);
        const previousStatus = mapStauts(jobs[jobId].status);
        const previousFilteredJobUpdated = filteredJobs[previousStatus].filter(id => id !== jobId);
        filteredJobs = {
          ...filteredJobs,
          [previousStatus]: previousFilteredJobUpdated,
          [updatedStatus]: [jobId, ...filteredJobs[updatedStatus]]
        };
      }
      jobs[jobId] = {...jobs[jobId], ...params};
      return {
        ...state,
        jobs,
        filteredJobs
      };
    }
  ),
  on(
    jobsActions.storeJobTasksSuccess,
    (state: JobsState, {jobId, tasks, nextToken}: {jobId: string; tasks: any[]; nextToken: string}): JobsState => {
      const jobs = {...state.jobs};
      const updatedTasks = [...(jobs[jobId]?.tasks || []), ...tasks];
      jobs[jobId] = {
        ...jobs[jobId],
        tasks: updatedTasks,
        totalTasks: updatedTasks.length
      };
      let isLoadingTasks = true;
      if (nextToken === undefined) {
        isLoadingTasks = false;
      }
      return {
        ...state,
        jobs,
        isLoadingTasks,
        nextTokenTasks: nextToken
      };
    }
  ),
  on(jobsActions.clearTaskList, (state: JobsState, {jobId}: {jobId: string}): JobsState => {
    const jobs = {...state.jobs};
    jobs[jobId] = {...jobs[jobId], tasks: null};
    return {
      ...state,
      jobs
    };
  }),
  on(jobsActions.storeJobTasks, (state: JobsState): JobsState => {
    return {
      ...state,
      isLoadingTasks: true
    };
  }),
  on(jobsActions.loadJobMarkers, (state: JobsState): JobsState => {
    return {
      ...state,
      isLoadingMarkers: true
    };
  }),
  on(jobsActions.storeJobs, (state: JobsState): JobsState => {
    return {
      ...state,
      isLoadingJobs: true
    };
  }),
  on(jobsActions.updateIsLoadingTasks, (state: JobsState, {isLoadingTasks}: {isLoadingTasks: boolean}): JobsState => {
    return {
      ...state,
      isLoadingTasks
    };
  }),
  on(jobsActions.updateIsLoadingJobs, (state: JobsState, {isLoadingJobs}: {isLoadingJobs: boolean}): JobsState => {
    return {
      ...state,
      isLoadingJobs
    };
  }),
  on(
    jobsActions.updateIsLoadingMarkers,
    (state: JobsState, {isLoadingMarkers}: {isLoadingMarkers: boolean}): JobsState => {
      return {
        ...state,
        isLoadingMarkers
      };
    }
  ),
  on(jobsActions.addTasksSuccess, (state: JobsState, {jobId, task}: {jobId: string; task: Task}): JobsState => {
    const jobs = {...state.jobs};
    const updatedTasks = jobs[jobId].tasks ? [task, ...jobs[jobId].tasks] : [task];
    jobs[jobId] = {...jobs[jobId], tasks: updatedTasks, totalTasks: (jobs[jobId].totalTasks || 0) + 1};
    return {
      ...state,
      jobs
    };
  }),
  on(jobsActions.updateIsAddingTasks, (state: JobsState, {isAddingTasks}: {isAddingTasks: boolean}): JobsState => {
    return {
      ...state,
      isAddingTasks
    };
  }),
  on(jobsActions.updateIsUpdatingJob, (state: JobsState, {isUpdatingJob}: {isUpdatingJob: boolean}): JobsState => {
    return {
      ...state,
      isUpdatingJob
    };
  }),
  on(
    jobsActions.deleteJobSuccess,
    (state: JobsState, {jobId, jobStatus}: {jobId: string; jobStatus: JOB_STATUS}): JobsState => {
      const jobs = {...state.jobs};
      delete jobs[jobId];
      const status = mapStauts(jobStatus);
      const filteredJobs = {...state.filteredJobs};
      const statusGroup = [...filteredJobs[status]];
      const jobIndex = statusGroup.indexOf(jobId);
      if (jobIndex !== -1) {
        statusGroup.splice(jobIndex, 1);
      }

      return {
        ...state,
        filteredJobs: {...state.filteredJobs, [status]: statusGroup},
        jobs
      };
    }
  ),
  on(jobsActions.deleteTaskSuccess, (state: JobsState, {taskId, jobId}: {taskId: string; jobId: string}): JobsState => {
    const jobs = {...state.jobs};
    const job = {...jobs[jobId]};
    const selectedJobTasks = [...job.tasks];
    job.tasks = selectedJobTasks?.filter(task => task.id !== taskId) || [];
    job.totalTasks = job.totalTasks - 1;
    jobs[jobId] = job;
    return {...state, jobs};
  }),
  on(jobsActions.resetTaskNextToken, (state: JobsState): JobsState => {
    return {...state, nextTokenTasks: null};
  }),
  on(
    jobsActions.updateTaskSuccess,
    (state: JobsState, {taskId, jobId, params}: {taskId: string; jobId: string; params: Partial<Task>}): JobsState => {
      const jobs = {...state.jobs};
      const tasks = [...jobs[jobId].tasks];
      const taskIndex = jobs[jobId].tasks.findIndex(task => task.id === taskId);
      if (taskIndex !== -1) {
        tasks[taskIndex] = {...tasks[taskIndex], ...params};
      }
      jobs[jobId] = {...jobs[jobId], tasks: tasks};
      return {
        ...state,
        jobs
      };
    }
  ),
  on(
    jobsActions.loadJobMarkersSuccess,
    (state: JobsState, data: {payload: {jobId: string; jobMarkers: any}}): JobsState => {
      return {
        ...state,
        jobsMakers: {
          ...state.jobsMakers,
          [data.payload.jobId]: data.payload.jobMarkers
        },
        isLoadingMarkers: false
      };
    }
  )
);

const mapStauts = (jobStatus: JOB_STATUS) => {
  const statusMapping = {
    [JOB_STATUS.COMPLETED]: 'complete',
    [JOB_STATUS.PUBLISHED]: 'published',
    [JOB_STATUS.DRAFT]: 'draft',
    [JOB_STATUS.FAILED]: 'failed',
    [JOB_STATUS.IN_PROGRESS]: 'inProgress'
  };
  return statusMapping[jobStatus];
};
