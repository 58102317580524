import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, map, of, switchMap, take} from 'rxjs';
import {LeavePageDialogComponent} from '@app/profile/components/leave-page-dialog/leave-page-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {MyProfileComponent} from '@app/profile/components/myprofile.component';
import {AuthFacade} from '@app/auth/state/auth.facade';
import {UserService} from '../services/api/user.service';
import {UserModel} from '../models/api/user-model';
import {AuthService} from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MobileNumberGuard {
  public canDeactivate(
    component: MyProfileComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.canLogOutOnSetMobilePhone) {
      return true;
    }
    return component.profileComponent.user$.pipe(
      switchMap(user => {
        const phoneForm = component.profileComponent.phoneForm;
        if (
          phoneForm.valid &&
          phoneForm.touched &&
          phoneForm.value.countryCode + phoneForm.value.mobileNumber !== user.phone
        ) {
          const dialog = this.dialog.open(LeavePageDialogComponent, {
            ...STANDARD_DIALOG_CONFIG,
            data: {hasFullNumber: true},
            disableClose: true,
            width: '50vw',
            minWidth: '200px',
            maxWidth: '800px'
          });
          return dialog.afterClosed().pipe(
            map(closedResponse => {
              if (closedResponse.openVerifyNumber) {
                component.profileComponent.verifyMobileNumber();
                return false;
              }
              return this.handleLogOut(closedResponse, nextState, true);
            })
          );
        }
        if (phoneForm.invalid && phoneForm.touched) {
          const dialog = this.dialog.open(LeavePageDialogComponent, {
            ...STANDARD_DIALOG_CONFIG,
            data: {hasFullNumber: false},
            disableClose: true,
            width: '50vw',
            minWidth: '200px',
            maxWidth: '800px'
          });
          return dialog.afterClosed().pipe(map(closedResponse => this.handleLogOut(closedResponse, nextState, false)));
        }
        return of(true);
      })
    );
  }

  public handleLogOut(closedResponse, nextState, returnValue): boolean {
    if (closedResponse.discardNumber && nextState.url === '/auth/sign-in') {
      this.authService.setCanLogOutOnSetMobilePhone(true);
      this.logout();
      return returnValue;
    }
    return !!closedResponse;
  }

  public logout(): void {
    this.userService.user$.pipe(take(1)).subscribe((user: UserModel) => {
      this.authFacade.authLogout(user.developer);
    });
  }
  constructor(
    private dialog: MatDialog,
    private authFacade: AuthFacade,
    private userService: UserService,
    private authService: AuthService
  ) {}
}
