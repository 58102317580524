import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, Observable} from 'rxjs';
import {Properties} from './interfaces';
import {IvyPinch} from './ivypinch';
import {defaultProperties} from './properties';

interface ComponentProperties extends Properties {
  disabled?: boolean;
  overflow?: 'hidden' | 'visible';
  zoomControl?: 'one-button' | 'two-buttons';
  disableZoomControl?: 'disable' | 'never' | 'auto';
  backgroundColor?: string;
  zoomControlPosition?: 'right' | 'right-bottom' | 'bottom';
  zoomModel?: 'white';
  canvasDiagonalWidth?: number;
  naturalImageDiagonalWidth?: number;
}

export const _defaultComponentProperties: ComponentProperties = {
  overflow: 'hidden',
  zoomControl: 'two-buttons',
  disableZoomControl: 'auto',
  backgroundColor: 'rgba(0,0,0,0.85)',
  zoomControlPosition: 'right',
  zoomModel: 'white',
  canvasDiagonalWidth: 0,
  naturalImageDiagonalWidth: 0
};

type PropertyName = keyof ComponentProperties;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pinch-zoom, [pinch-zoom]',
  exportAs: 'pinchZoom',
  templateUrl: './pinch-zoom.component.html',
  styleUrls: ['./pinch-zoom.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PinchZoomComponent implements OnDestroy {
  public pinchZoom: IvyPinch;
  public zoomControlPositionClass: string | undefined;
  public _transitionDuration!: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _doubleTap!: boolean;
  public _doubleTapScale!: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _autoZoomOut!: boolean;
  public _limitZoom!: number | 'original image size';

  private isDisabled: boolean;

  private zoomPercentage: BehaviorSubject<number> = new BehaviorSubject(null);
  public zoomPercentage$: Observable<number> = this.zoomPercentage.asObservable().pipe(distinctUntilChanged());

  private isZoomIn: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public isZoomIn$: Observable<boolean> = this.isZoomIn.asObservable().pipe(distinctUntilChanged());

  private isZoomLimitReached: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public isZoomLimitReached$: Observable<boolean> = this.isZoomLimitReached.asObservable().pipe(distinctUntilChanged());

  private hasOneControl: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public hasOneControl$: Observable<boolean> = this.hasOneControl.asObservable().pipe(distinctUntilChanged());

  private hasTwoControls: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public hasTwoControls$: Observable<boolean> = this.hasTwoControls.asObservable().pipe(distinctUntilChanged());

  @Input('properties') public set setupProperties(value: ComponentProperties) {
    if (value) {
      this.properties = {...defaultProperties, ..._defaultComponentProperties, ...this.properties, ...value};
      this.isDisabled = this.properties['disabled'];
      this.isControl(this.properties['zoomControl']);
    }
  }
  public properties: ComponentProperties = {};

  // transitionDuration
  @Input('transition-duration') public set transitionDurationBackwardCompatibility(value: number) {
    if (value) {
      this._transitionDuration = value;
    }
  }

  @Input('transitionDuration') public set transitionDuration(value: number) {
    if (value) {
      this._transitionDuration = value;
    }
  }

  public get transitionDuration() {
    return this._transitionDuration;
  }

  // doubleTap
  @Input('double-tap') public set doubleTapBackwardCompatibility(value: boolean) {
    if (value) {
      this._doubleTap = value;
    }
  }

  @Input('doubleTap') public set doubleTap(value: boolean) {
    if (value) {
      this._doubleTap = value;
    }
  }

  public get doubleTap() {
    return this._doubleTap;
  }

  // doubleTapScale
  @Input('double-tap-scale') public set doubleTapScaleBackwardCompatibility(value: number) {
    if (value) {
      this._doubleTapScale = value;
    }
  }

  @Input('doubleTapScale') public set doubleTapScale(value: number) {
    if (value) {
      this._doubleTapScale = value;
    }
  }

  public get doubleTapScale() {
    return this._doubleTapScale;
  }

  // autoZoomOut
  @Input('auto-zoom-out') public set autoZoomOutBackwardCompatibility(value: boolean) {
    if (value) {
      this._autoZoomOut = value;
    }
  }

  @Input('autoZoomOut') public set autoZoomOut(value: boolean) {
    if (value) {
      this._autoZoomOut = value;
    }
  }

  public get autoZoomOut() {
    return this._autoZoomOut;
  }

  // limitZoom
  @Input('limit-zoom') public set limitZoomBackwardCompatibility(value: number | 'original image size') {
    if (value) {
      this._limitZoom = value;
    }
  }

  @Input('limitZoom') public set limitZoom(value: number | 'original image size') {
    if (value) {
      this._limitZoom = value;
    }
  }

  public get limitZoom() {
    return this._limitZoom;
  }

  @Input('disabled') public disabled!: boolean;
  @Input() public disablePan!: boolean;
  @Input() public overflow!: 'hidden' | 'visible';
  @Input() public zoomControlScale: number = 1;
  @Input() public zoomControl!: 'one-button' | 'two-buttons';
  @Input() public disableZoomControl!: 'disable' | 'never' | 'auto';
  @Input() public zoomControlPosition!: 'right' | 'right-bottom' | 'bottom';
  @Input() public backgroundColor!: string;
  @Input() public limitPan!: boolean;
  @Input() public minPanScale!: number;
  @Input() public minScale!: number;
  @Input() public listeners!: 'auto' | 'mouse and touch';
  @Input() public wheel!: boolean;
  @Input() public fullImage!: {
    path: string;
    minScale?: number;
  };
  @Input() public autoHeight!: boolean;
  @Input() public wheelZoomFactor!: number;
  @Input() public draggableImage!: boolean;

  @Input('canvasDiagonalWidth') public set setupCanvasDiagonalWidth(value) {
    this.canvasDiagonalWidth = value;
    this.updatePinchZoomUIState();
  }

  @Input('naturalImageDiagonalWidth') public set setupNaturalImageDiagonalWidth(value) {
    this.naturalImageDiagonalWidth = value;
    this.updatePinchZoomUIState();
  }

  @Input('canPanImage') public set setCanPanImage(value: boolean) {
    if (this.pinchZoom) {
      this.pinchZoom.canPanImage = value;
    }
  }

  public canvasDiagonalWidth!: number;
  public naturalImageDiagonalWidth!: number;

  @Output() public events: EventEmitter<any> = new EventEmitter();
  @Output() public isZoomEnabled: EventEmitter<boolean> = new EventEmitter();
  @Output() public zoomInEnded: EventEmitter<void> = new EventEmitter();
  @Output() public zoomOutEnded: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.overflow')
  public hostOverflow() {
    return this.properties?.['overflow'];
  }

  @HostBinding('style.background-color')
  public hostBackgroundColor() {
    return this.properties?.['backgroundColor'];
  }

  public get isTouchScreen() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = function (query: any) {
      return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  public get isDragging() {
    this.updatePinchZoomUIState();
    return this.pinchZoom ? this.pinchZoom.isDragging() : undefined;
  }

  public emitIsZoomEnabled() {
    if (!this.pinchZoom) {
      return null;
    }

    this.pinchZoom.scale === 1 ? this.isZoomEnabled.emit(false) : this.isZoomEnabled.emit(true);
  }

  private updatePercentageText() {
    const ONE_HUNDRED_PERCENTAGE = 100;
    const TWO_DECIMALS = 2;

    const percentage = (this.canvasDiagonalWidth * ONE_HUNDRED_PERCENTAGE) / this.naturalImageDiagonalWidth;
    this.zoomPercentage.next(Number.parseFloat(percentage.toFixed(TWO_DECIMALS)));
  }

  public get x() {
    return this.pinchZoom.moveX;
  }

  public get y() {
    return this.pinchZoom.moveY;
  }

  public get scaleLevel() {
    return Math.round(this.pinchZoom.scale / this.zoomControlScale);
  }

  public get maxScale() {
    return this.pinchZoom?.maxScale;
  }

  constructor(private elementRef: ElementRef) {}

  public ngOnInit() {
    this.initPinchZoom();

    /* Calls the method until the image size is available */
    this.detectLimitZoom();
  }

  public ngOnDestroy() {
    this.destroy();
  }

  public initPinchZoom() {
    if (this.isDisabled) {
      return;
    }

    this.zoomControlPositionClass = this.getZoomControlPositionClass();
    this.properties['element'] = this.elementRef.nativeElement.querySelector('.pinch-zoom-content');
    this.properties['eventHandler'] = this.events;
    this.pinchZoom = new IvyPinch({...this.properties, zoomControlScale: this.zoomControlScale});
  }

  public toggleZoom() {
    this.pinchZoom.toggleZoom();
  }

  public isControl(mode: 'one-button' | 'two-buttons') {
    if (this.isDisabled) {
      this.hasOneControl.next(false);
      this.hasTwoControls.next(false);
      return;
    }

    if (this.properties['disableZoomControl'] === 'disable') {
      this.hasOneControl.next(false);
      this.hasTwoControls.next(false);
      return;
    }

    if (this.isTouchScreen && this.properties['disableZoomControl'] === 'auto') {
      this.hasOneControl.next(false);
      this.hasTwoControls.next(false);
      return;
    }

    if (mode === 'one-button' && this.properties['zoomControl'] === mode) {
      this.hasOneControl.next(true);
      return;
    }

    if (mode === 'two-buttons' && this.properties['zoomControl'] === mode) {
      this.hasTwoControls.next(true);
      return;
    }
  }

  public getZoomControlPositionClass() {
    const prefix = 'pz-zoom-control-position-';

    if (this.properties['zoomControlPosition']) {
      return prefix + this.properties['zoomControlPosition'];
    }

    if (this.properties['zoomControl'] === 'one-button') {
      return prefix + 'bottom';
    }

    if (this.properties['zoomControl'] === 'two-buttons') {
      return prefix + 'right';
    }

    return undefined;
  }

  public detectLimitZoom() {
    if (this.pinchZoom) {
      this.pinchZoom.detectLimitZoom();
    }
  }

  public setTransform(properties: {x?: number; y?: number; scale?: number; transitionDuration?: number}) {
    this.pinchZoom.setTransform(properties);
  }

  public setZoom(properties: {scale: number; center?: number[]}) {
    this.pinchZoom.setZoom(properties);
  }

  public zoomIn(): void {
    const isZoomLimitReached = this.pinchZoom.scale >= this.maxScale;
    if (isZoomLimitReached) {
      return;
    }

    let newScale = this.pinchZoom.scale + this.zoomControlScale;
    if (newScale > this.maxScale) {
      newScale = this.maxScale;
    }

    this.setZoom({
      scale: newScale
    });

    this.zoomInEnded.emit();
  }

  public zoomOut() {
    const scale: number = this.pinchZoom.scale - this.zoomControlScale;

    const minScale = this.properties.minScale || 0;

    if (scale <= minScale) {
      if (minScale === 1) {
        this.reset();
        this.zoomOutEnded.emit();
      }
      return;
    }

    this.setZoom({
      scale: scale
    });

    this.zoomOutEnded.emit();
  }

  public reset() {
    this.pinchZoom.resetScale();
  }

  public destroy() {
    this.pinchZoom.destroy();
  }

  public updatePinchZoomUIState() {
    if (!this.pinchZoom) {
      return;
    }

    if (this.pinchZoom.scale >= this.maxScale) {
      const isZoomLimitReached = this.pinchZoom.scale >= this.maxScale;
      this.isZoomLimitReached.next(isZoomLimitReached);
    }

    const isZoomIn = this.pinchZoom.scale > this.properties.minScale;
    this.isZoomIn.next(isZoomIn);
    this.emitIsZoomEnabled();
    this.updatePercentageText();
  }
}
