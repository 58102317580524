import {Component, ViewChild} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';

@Component({
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyProfileComponent {
  @ViewChild('profileComponent') public profileComponent: UserProfileComponent;

  public aclPermissions = AclPermissions;

  constructor(private userStoreFacadeService: UserStoreFacadeService) {
    this.userStoreFacadeService.getCompanyDataIfUserIsAdmin();
  }
}
