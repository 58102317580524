import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UserService} from '@app/core/services/api/user.service';
import {BehaviorSubject, map, Observable, take} from 'rxjs';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeveloperComponent {
  public isActivating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDeveloperMode$: Observable<boolean> = this.userService.user$.pipe(
    map(user => {
      return coerceBooleanProperty(user.developer);
    })
  );

  constructor(private userService: UserService) {}

  public activateDeveloperMode(): void {
    this.isActivating.next(true);
    this.userService.updateUser({
      developer: true
    });
    this.userService.user$.pipe(take(1)).subscribe(
      resp => {
        this.isActivating.next(false);
      },
      err => {
        console.error(err);
        this.isActivating.next(false);
      }
    );
  }

  public turnOffDeveloperMode(): void {
    this.isActivating.next(true);
    this.userService.updateUser({
      developer: false
    });
    this.userService.user$.pipe(take(1)).subscribe(
      resp => {
        this.isActivating.next(false);
      },
      err => {
        console.error(err);
        this.isActivating.next(false);
      }
    );
  }
}
