<div class="container">
  <div class="section-name">{{ 'profile.developerMode' | translate }}</div>
  <div class="content mat-elevation-z5">
    <ng-container [ngSwitch]="isDeveloperMode$ | async">
      <ng-container *ngSwitchCase="true">
        <div class="option">
          <div class="option-title status-info">
            <mat-icon svgIcon="status" class="status-icon"></mat-icon>
            {{ 'profile.developerModeIsActive' | translate }}
          </div>
          <div class="option-content">
            <a mat-button class="link" [appLadda]="isActivating | async" (click)="turnOffDeveloperMode()">
              {{ 'profile.turnOffDeveloperMode' | translate }}
            </a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <div class="option">
          <div class="option-title">
            {{ 'profile.developerModeInfo' | translate }}
          </div>
          <div class="option-content">
            <button
              mat-raised-button
              class="dev-mode-activation-button"
              color="primary"
              [appLadda]="isActivating | async"
              (click)="activateDeveloperMode()"
            >
              {{ 'profile.activateDeveloperMode' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
