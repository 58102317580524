import {Directive, HostListener, Input} from '@angular/core';
import {AtlasHeaderService, AtlasHeaderStatus} from '../services/atlas-header.service';
import {AtlasSelectMarkersSettingsService} from '../services/atlas-select-markers-settings.service';
import {AtlasSelectMarkersService} from '../services/atlas-select-markers.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {LatLngBounds, Map} from 'leaflet';
import {LayersVisibilityService} from '../services/layers-visibility.service';

@Directive({
  selector: '[ulEditJobSelection]'
})
export class EditJobSelectionDirective {
  @Input('ulEditJobSelection') public jobName: string;
  @Input() public baseAsset: AtlasAssetModel;
  @Input() public polygonAsset: AtlasAssetModel;
  @Input() public map: Map;
  @Input() public avoidFitBounds: boolean = false;

  constructor(
    private atlasHeaderService: AtlasHeaderService,
    private atlasSelectMarkersSettingsService: AtlasSelectMarkersSettingsService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private layersVisibilityService: LayersVisibilityService
  ) {}

  @HostListener('click', ['$event'])
  public enableEditMode(): void {
    const leafletLayer = (this.polygonAsset.leafletLayer as any).getLayers();
    this.atlasHeaderService.setEditJobLayerTitle(this.jobName);
    this.atlasHeaderService.setHeaderStatus(AtlasHeaderStatus.EDIT_JOB_SHAPE);
    this.atlasSelectMarkersSettingsService.showSelectOptions();
    this.atlasSelectMarkersService.clearSelections(this.map);
    this.atlasSelectMarkersService.setEditingShape(leafletLayer, this.polygonAsset);
    this.atlasSelectMarkersService.setCurrentLayer(this.baseAsset);
    this.atlasSelectMarkersService.listenEditEvents(this.map, leafletLayer);
    this.atlasSelectMarkersService.enableEditFeature(this.map);
    leafletLayer.forEach(layer => {
      this.atlasSelectMarkersService.verifyMarkersInsideBox(layer, false, this.map);
    });
    this.layersVisibilityService.displayLayersToBeSelectedOnly(this.baseAsset.id, this.polygonAsset.id, this.map);
    this.map.invalidateSize();
    const mapBounds = this.map.getBounds();
    const isLayerInTheView = mapBounds.intersects(this.polygonAsset.bounds);
    if (!isLayerInTheView) {
      this.map.fitBounds(this.polygonAsset.bounds as LatLngBounds);
    }
  }
}
