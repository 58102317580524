import {Injectable} from '@angular/core';
import {latLng, latLngBounds, Map, marker} from 'leaflet';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {ReplaySubject} from 'rxjs';
import {SharedSuperclusterService} from '@app/shared/services/supercluster/shared-supercluster.service';
import {atlasConfig} from '../atlas.config';

@Injectable({
  providedIn: 'root'
})
export class SuperclusterService extends SharedSuperclusterService {
  public markers: L.Marker[] = [];
  public supercluster;
  public displayedClusters = [];
  public stickyClustersEnabled = false;
  public hiddenAssets = [];
  public updatedColorAssets: {[key: string]: string} = {};
  public updatedMarkerZIndex: {[key: string]: number} = {};
  public finishUpdate: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private geojsonAssetLoaderService: GeojsonAssetLoaderService) {
    super();
    this.setClusterThreshold(atlasConfig.DISABLE_CLUSTERING_MARKERS_COUNT);
  }

  public setNewAssetColor(color: string, id: string) {
    this.updatedColorAssets[id] = color;
  }

  public setUpdatedMarkerZIndex(zIndex: number, id: string) {
    this.updatedMarkerZIndex[id] = zIndex;
  }

  public restoreHiddenAssets() {
    this.hiddenAssets = [];
  }

  public addHiddenAssets(assetId: string): void {
    this.hiddenAssets = [...new Set([...this.hiddenAssets, assetId])];
  }

  public removeHiddenAssets(assetId: string): void {
    if (this.hiddenAssets.length === 0) {
      return;
    }
    const index = this.hiddenAssets.indexOf(assetId);
    if (index !== -1) {
      this.hiddenAssets.splice(index, 1);
    }
  }

  public updateClusters(map: Map): void {
    if (!map || !this.supercluster) {
      return;
    }
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    this.displayedClusters = this.supercluster.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      zoom
    );
    this.markers.forEach(marker => map.removeLayer(marker));
    this.markers = [];
    if (!this.isClusterEnabled) {
      this.updateUnclusteredMarkers(map);
      return;
    }
    this.displayedClusters.forEach(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const isCluster = cluster.properties.cluster;
      if (isCluster) {
        let pointCount = cluster.properties?.point_count;
        let clusterColor = cluster.properties?.clusterColor;
        const clusterPoints = this.supercluster.getLeaves(cluster.properties.cluster_id, Infinity);
        const hasUpdatedColorAssets = Object.keys(this.updatedColorAssets).length > 0;
        const hasHiddenAssets = this.hiddenAssets.length > 0;
        if (hasUpdatedColorAssets) {
          //for changed color assets
          clusterColor = this.updateClusterColor(clusterPoints);
        }
        if (hasHiddenAssets) {
          //check if the asset is hidden
          const filteredPoints = clusterPoints.filter(
            clusterPoint => !this.hiddenAssets.includes(clusterPoint.asset.id)
          );
          if (filteredPoints.length === 0) {
            return;
          }
          if (filteredPoints.length === 1) {
            const point = filteredPoints[0];
            this.addSingleMarker(point, point.geometry.coordinates[1], point.geometry.coordinates[0], map);
            return;
          }
          if (clusterPoints.length !== filteredPoints.length) {
            pointCount = filteredPoints.length;
            clusterColor = filteredPoints.reduce((acc, obj) => {
              acc[obj.properties.featureColor] = (acc[obj.properties.featureColor] || 0) + 1;
              return acc;
            }, {});
          }
        }
        //create cluster icon
        const clusterMarker = marker([latitude, longitude], {
          icon: this.getIconCreateFunction(pointCount, clusterColor)
        });

        clusterMarker.on('click', () => {
          // zoom cluster on click
          const clusterBounds = latLngBounds(
            clusterPoints.map(point => latLng(point.geometry.coordinates[1], point.geometry.coordinates[0]))
          );
          map.fitBounds(clusterBounds);
        });

        this.markers.push(clusterMarker);
        clusterMarker.addTo(map);
      } else {
        //for markers only
        this.addSingleMarker(cluster, latitude, longitude, map);
      }
    });
    this.finishUpdate.next();
  }

  public addSingleMarker(point, latitude, longitude, map) {
    const isMarkerHidden = this.hiddenAssets.includes(point.asset.id);
    if (isMarkerHidden) {
      return;
    }
    // Individual marker no cluster
    const marker = this.geojsonAssetLoaderService.generateMarker(point, latLng(latitude, longitude), point.asset);
    marker.feature = point;
    if (point.properties.selectedIcon) {
      //for select markers
      marker.setIcon(point.properties.selectedIcon);
    }
    this.markers.push(marker);
    marker.addTo(map);
    if (this.updatedMarkerZIndex[point.asset.id]) {
      marker.setZIndexOffset(this.updatedMarkerZIndex[point.asset.id]);
    }
    this.geojsonAssetLoaderService.onEachFeature(point, marker, point.asset, map);
  }

  public updateClusterColor(clusterPoints: any[]) {
    clusterPoints.forEach(point => {
      if (this.updatedColorAssets[point.asset.id]) {
        point.properties.featureColor = this.updatedColorAssets[point.asset.id];
      }
      return point;
    });
    const clusterColor = clusterPoints.reduce((acc, obj) => {
      acc[obj.properties.featureColor] = (acc[obj.properties.featureColor] || 0) + 1;
      return acc;
    }, {});
    return clusterColor;
  }

  public toggleClusterItemsVisibility(isDisplaying: boolean, assetId: string) {
    isDisplaying ? this.removeHiddenAssets(assetId) : this.addHiddenAssets(assetId);
  }
}
