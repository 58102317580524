import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from '../../../core/models/api/user-model';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {GaugeComponent} from './gauge/gauge.component';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, GaugeComponent]
})
export class UsageComponent implements OnInit {
  @Input() isAwsUser: boolean;
  @Input('user') user: UserModel;

  librarySize: number;
  librarySizeMax: number;

  constructor() {}

  ngOnInit() {
    this.librarySize = this.fromBytesToGiB(this.user.currentUsage.librarySize);
    this.librarySizeMax = this.fromBytesToGB(this.user.currentUsage.librarySizeMax);
  }

  fromBytesToGB(value) {
    return Math.round((value / 1000_000_000) * 100) / 100; // note: plan limits are in gigabytes not in gibibytes, round to 2 decimal places
  }
  fromBytesToGiB(bytes) {
    const gibibytes = bytes / 1024 ** 3; // 1024^3 is 1 GiB in bytes
    return Math.round((gibibytes + Number.EPSILON) * 100) / 100;
  }
}
