  <mat-card-header>
    <div class="header">
      <unleash-search-input (search)="performSearch($event)"></unleash-search-input>
      <button (click)="emitAddTeam()" data-test-id="add-users-button" mat-button color="primary">
        <mat-icon>groups</mat-icon> {{ 'profile.teams.create' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <unleash-teams-table
        [dataSource]="teams"
        [menuItems]="menuItems"
        (user)="emitTeam($event)"
        (action)="emitAction($event)"
      ></unleash-teams-table>
    </div>
  </mat-card-content>