<div class="container">
  <div class="section-name">{{ 'profile.headers.usage' | translate }}</div>
  <div class="content mat-elevation-z5">
    <app-usage
      *ngIf="user$ | async as user; else showSpinnerTemplate"
      [user]="user"
      [isAwsUser]="isAwsUser$ | async"
    ></app-usage>
  </div>
</div>

<div class="container" *uaHasPermission="aclPermissions.MainApiReadPlan">
  <div class="section-name">{{ 'profile.headers.package' | translate }}</div>
  <div class="content mat-elevation-z5">
    <ng-container *ngIf="userPlan$ | async as userPlan; else showSpinnerTemplate">
      <ng-container *ngIf="userPlan.reseller; else miniPlanPackagesTemplate">
        <app-plan *ngIf="user$ | async as user; else showSpinnerTemplate" [user]="user"></app-plan>
      </ng-container>
      <ng-template #miniPlanPackagesTemplate>
        <unleash-mini-plan-viewer
          [user]="user$ | async"
          [userPlan]="userPlan$ | async"
          [isAwsUser]="isAwsUser$ | async"
        ></unleash-mini-plan-viewer>
      </ng-template>
    </ng-container>
  </div>
</div>

<div class="container">
  <div class="section-name">{{ 'profile.headers.organizationDetails' | translate }}</div>
  <div class="content mat-elevation-z5" fxLayout.lt-sm="row wrap">
    <form [formGroup]="form" fxFlex="100%" fxFlex.lt-sm="100%">
      <div class="option">
        <mat-form-field class="form-field-name" appearance="fill">
          <mat-label>{{ 'profile.organizationName' | translate }}</mat-label>
          <input
            type="text"
            matInput
            (change)="update('name')"
            formControlName="name"
            placeholder="{{ 'profile.organizationName' | translate }}"
            required
            [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
          />
          <mat-error *ngIf="form.get('name').hasError('required')">
            {{ 'profile.organizationName' | translate }} {{ 'common.errors.isRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('name').hasError('maxlength')">
            {{ 'profile.organizationName' | translate }} {{ 'common.errors.tooLong' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="option">
        <mat-form-field class="form-field-slug" appearance="fill">
          <mat-label>{{ 'profile.livestreamDashboardName' | translate }}</mat-label>
          <input
            type="text"
            matInput
            (change)="update('slug')"
            formControlName="slug"
            placeholder="{{ 'profile.livestreamDashboardName' | translate }}"
            required
            [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
          />
          <mat-error *ngIf="form.get('slug').hasError('required')">
            {{ 'profile.livestreamDashboardName' | translate }} {{ 'common.errors.isRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="updateErrorMessage" [innerHTML]="updateErrorMessage"> </mat-error>
        </mat-form-field>
      </div>
      <div class="option">
        <mat-form-field class="form-field-website" appearance="fill">
          <mat-label>{{ 'common.website' | translate }}</mat-label>
          <input
            type="text"
            matInput
            (change)="update('website')"
            formControlName="website"
            placeholder="{{ 'common.website' | translate }}"
            [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
          />
          <mat-error *ngIf="form.get('website').hasError('pattern')">
            {{ 'profile.invalidURL' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="option">
        <mat-form-field class="form-field-industry" appearance="fill">
          <mat-label>{{ 'common.industry' | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'common.industry' | translate }}"
            formControlName="industry"
            (selectionChange)="update('industry')"
            [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
          >
            <mat-option *ngFor="let industry of industryNames" name="industry" [value]="industry">
              {{ industry }}
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option value="other">
              {{ 'common.other' | translate }}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.get('industry').hasError('required')">
            {{ 'profile.chooseIndustry' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="option">
        <mat-form-field class="form-field-name" appearance="fill">
          <mat-label>{{ 'profile.dateFormat' | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'profile.dateFormat' | translate }}"
            (selectionChange)="update('dateFormat')"
            formControlName="dateFormat"
          >
            <mat-select-trigger>
              {{ currentDate | strftimeToDateFormatPipe: dateFormatInfo[form.controls.dateFormat.value]?.value }}
            </mat-select-trigger>

            <mat-option *ngFor="let dateFormat of dateFormatOptions" [value]="dateFormat.value">
              <div class="date-format">{{ currentDate | strftimeToDateFormatPipe: dateFormat.value }}</div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('dateFormat').hasError('required')">
            {{ 'profile.dateFormatRequired' | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <div class="option">
        <mat-form-field class="form-field-name" appearance="fill">
          <mat-label>{{ 'profile.timezone' | translate }}</mat-label>
          <mat-select
            placeholder="{{ 'profile.timezone' | translate }}"
            (selectionChange)="update('timezone')"
            formControlName="timezone"
          >
            <mat-option *ngFor="let timezone of timezones" [value]="timezone">
              {{ timezone }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('timezone').hasError('required')">
            {{ 'profile.timezoneRequired' | translate }}</mat-error
          >
        </mat-form-field>
      </div>
    </form>
    <div
      fxFlex="50%"
      fxFlex.lt-sm="100%"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="start start"
    >
      <div class="picture">
        <div class="picture-title">{{ 'common.logo' | translate }}</div>
        <ng-container *ngIf="company$ | async as company">
          <ng-container *uaHasPermission="aclPermissions.AccountApiUploadImage; else showLogoTemplate">
            <logo-upload
              subfolder="company"
              [parentId]="company?.id"
              [url]="company.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.organisationCompanyLogo"
              [inputBucket]="uploadLogoSource.ACCOUNT"
              (s3Url)="updateCompanyLogo($event)"
            ></logo-upload>
          </ng-container>
          <ng-template #showLogoTemplate>
            <div class="picture__container">
              <img
                class="picture__image"
                [src]="company.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.organisationCompanyLogo"
              />
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #showSpinnerTemplate>
  <div class="spinner">
    <unleash-spinner diameter="40" borderWidth="3" color="#26bb6f"></unleash-spinner>
  </div>
</ng-template>
